import { getAdventurerURL, makeid } from "~/config/const";

export const createRandomHighscores = (n: number) => {
    let scores = Array.from({ length: n }, () => Math.floor(Math.random() * 1000));
    scores.sort((a, b) => b - a);
  
    let usernames = Array.from({ length: n }, () => makeid(8));
  
    return usernames.map((username, idx) => ({
      username: username,
      score: scores[idx],
      avatar: getAdventurerURL(username),
      color: 'white',
    }));
  };
  