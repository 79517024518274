import { getAdventurerURL, makeid } from "~/config/const";
import { LoginType } from "./LoginType";

export const UserBuilder = {
    makeRandom() {
        const UID = makeid(4);
        return {
            uid: UID,
            username: `Guest_${UID}`,
            avatar_url: getAdventurerURL(UID),
        };
    },
    makeFromAuth(auth: any) {
        let account_type;
        if (auth.isAnonymous)
            account_type = LoginType.ANONYMOUS
        else if (auth.isGoogle)
            account_type = LoginType.GOOGLE
        else if (auth.isFacebook)
            account_type = LoginType.FACEBOOK
        else if (auth.isPassword)
            account_type = LoginType.PASSWORD

        return {
            uid: auth.uid,
            email: auth.email,
            username: auth.name,
            avatar_url: '',
            login_type: account_type,
            roles: {
                admin: false,
                developer: false,
            }
        }
    }
}