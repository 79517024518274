import { HighScore, SocketIoApi } from '@unboared/lib'
import { db } from '~/services/firebase'
import {
    collection,
    doc,
    getDoc,
    orderBy as fbOrderBy,
    setDoc,
    addDoc,
    serverTimestamp,
    updateDoc,
} from 'firebase/firestore';
import { DEFAULT_LEVEL_NAME } from '~/config/const';
import { retrieveHighscores as getHighscores } from '~/services/scores_api'

export class PlatformApi extends SocketIoApi {

    /**
     * Create a new webview api interface
     */
    constructor(initialScene: string, deviceType: 'gamepad' | 'screen', server: string) {
        super(initialScene, deviceType, server)
    }

    /**
     * This function saves a persistent data in the database 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string} key the key 
     * @param {any} data the data to persist
     */
    savePersistentData(gameID: string, deviceID: string, key: string, data: any): void {
        let uid = this.getUID(deviceID);
        let gameDataRef = doc(db, "users", uid, "gameData", gameID);

        updateDoc(gameDataRef, {
            [key]: data
        }).then(() => {
            this.unboaredEventManager.dispatch('onPersistentDataSaved', { gameID, deviceID, key, data })
        }).catch((err) => {
            console.error("Cannot get highscore.")
            console.error(err)
        })

    }

    /**
     * Retrieve a persistent data 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string} key the key 
     */
    retrievePersistentData(gameID: string, deviceID: string, key: string): void {
        let uid = this.getUID(deviceID);
        let gameDataRef = doc(db, "users", uid, "gameData", gameID);

        getDoc(gameDataRef).then((doc: any) => {
            if (doc.exists()) {
                let gameData = doc.data();
                if (key in gameData) {
                    this.unboaredEventManager.dispatch('onPersistentDataSaved', { gameID, deviceID, key, data: gameData[key] })
                }
            }
        }).catch((err) => {
            console.error("Cannot get highscore.")
            console.error(err)
        })
    }

    /**
     * This function saves highscore for a player
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {number} score the score to be saved 
     * @param {string|undefined} level the level identifier
     */
    saveHighscore(gameID: string, deviceID: string, score: number, level?: string): void {
        let uid = this.getUID(deviceID);
        let levelName = level || DEFAULT_LEVEL_NAME

        let highscoreRef = doc(db, "games", gameID, "highscores", levelName, "users", uid);
        let scoresCollRef = collection(db, "users", uid, "gameData", gameID, "levels", levelName, "scores");

        let newScore = {
            date: serverTimestamp(),
            score: score,
        }
        addDoc(scoresCollRef, newScore).then(() => {
            getDoc(highscoreRef)
                .then((doc: any) => {
                    if (doc.exists()) {
                        let highscore = doc.data();
                        if (highscore.score < score) {
                            updateDoc(highscoreRef, newScore).catch((err) => {
                                console.error("Cannot update highscore.")
                                console.error(err)
                            })
                        }
                    }
                    else {
                        setDoc(highscoreRef, newScore).catch((err) => {
                            console.error("Cannot create highscore.")
                            console.error(err)
                        })
                    }
                    this.unboaredEventManager.dispatch('onHighscoreSaved', { gameID, deviceID, level })
                })
                .catch((err) => {
                    console.error("Cannot get highscore.")
                    console.error(err)
                });

        }).catch((err) => {
            console.error("Cannot add highscore.")
            console.error(err)
        })
    }

    /**
     * This function retrieves a highscore for a specific player in the database 
     * @param {string} gameID the game identifier
     * @param {string} deviceID the device identifier
     * @param {string|undefined} level the level identifier
     */
    retrieveHighscore(gameID: string, deviceID: string, level?: string): void {
        let uid = this.getUID(deviceID);
        let levelName = level || DEFAULT_LEVEL_NAME

        let highscoreRef = doc(db, "games", gameID, "highscores", levelName, "users", uid);
        getDoc(highscoreRef)
            .then((doc: any) => {
                if (doc.exists()) {
                    let highscore = doc.data();
                    this.unboaredEventManager.dispatch('onHighscoreRetrieve', {
                        gameID, deviceID, highscore: {
                            username: this.getUsername(deviceID),
                            avatar: this.getAvatar(deviceID),
                            score: highscore.score,
                            date: highscore.date,
                        }, level
                    })
                }
            })
            .catch((err) => {
                console.error("Cannot get highscore.")
                console.error(err)
            });
    }

    retrieveHighscores(gameID: string, total: number, group?: string | Array<string>, level?: string) {
        getHighscores(gameID, total, group, level).then((highscores) => {
            this.unboaredEventManager.dispatch('onHighscoresRetrieve', {
                gameID, group, highscores: highscores, level
            })
        }).catch((err) => {
            console.error('Cannot get highscores.');
            console.error(err);
        })
    }
}