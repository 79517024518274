import React from "react";
import { ColorValue, StyleSheet, View } from "react-native";
import { Text, useNormalize } from "@unboared/base-ui.all";

/**
 * The score displayed on players' badges on the podium.
 */
export const ScoreIcon = ({ color, score, maxScore, size, shadow }: any) => {
    /* Create styles */
    const { normalize } = useNormalize();
    const styles = scoreIconStyles(normalize, size, color, shadow)

    /* The score as a text */
    const scoreText = `${score}${maxScore ? ` / ${maxScore}` : ''}`

    return (
        <View style={styles.container}>
            <Text style={styles.score} text={scoreText} />
        </View>
    );
};

/* Stylesheet */
const scoreIconStyles = (normalize: (x: number) => number, size: number, color: ColorValue, shadow: boolean) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: 0,
            right: -normalize(0.2 * size),
            backgroundColor: '#FFF',
            borderRadius: 400,
            justifyContent: 'center',
            textAlign: 'center',
            width: normalize(0.5 * size),
            height: normalize(0.5 * size),
            boxShadow: shadow ? `0px 0px ${0.3 * size}px #FFF` : '',
        },
        score: {
            color: color,
            fontWeight: 'bold',
            fontSize: normalize(0.17 * size),
        }
    })