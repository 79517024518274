import React from 'react';
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import { useNormalize, useTranslate, accent_color, Text } from '@unboared/base-ui.all';

import { useMargin } from './hooks/useMargin';
import { log } from '~/config/env';
import { useAuth } from '~/services/auth';
import { ActivityIndicator } from '~/components/common/loaders';
import { useAnalyticsAccessEvent } from '~/services/firebase';
import { EmailPasswordSignIn } from './components/email_password';
import { CreateAccountLink } from './components/create_account_link';

/**
 * Sign in page.
 */
export const SignIn = () => {
  log.debug('Mount SignIn');
  useAnalyticsAccessEvent('platform/signin')

  const { normalize } = useNormalize();
  const styles = makeStyles(normalize)

  const loading = useAuth((state) => state.loading);

  return (
    <View style={[styles.flexCenter, styles.container]}>
      <View style={styles.flexCenter}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.content}>
            <EmailPasswordSignIn />
            <CreateAccountLink />
          </View>
        )}
      </View>
    </View>
  );
};

const makeStyles = (normalize: any) => StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  flexCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: normalize(250),
    justifyContent: 'center',
  },
})

export default SignIn;
