import styled from 'rn-css';

export const RankIconContainer = styled.View<{
  backgroundColor: string;
  borderColor: string;
  size: number;
  avatarSize: number;
}>`
  position: absolute;
  top: ${(props) => -props.size / 2};
  left: ${(props) => (props.avatarSize - 2 * props.size) / 2};
  background-color: ${(props) => props.backgroundColor};
  border: 2 solid ${(props) => props.borderColor};
  height: ${(props) => props.size};
  width: ${(props) => 2 * props.size};
  border-radius: ${(props) => props.size};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
`;