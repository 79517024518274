import React from 'react';
import { View, StyleSheet, ImageBackground } from 'react-native';
import styled from 'rn-css';

import {
  Text,
  Heading,
  accent_color,
  Button,
  unboaredTheme,
  ThemeProvider,
} from '@unboared/base-ui.all';

import { Logo } from '@unboared/design.all';

import { ASSETS } from '~/config/const';
import { useNormalize } from '@unboared/base-ui.responsive.sizes';
import { space } from '@unboared/base-ui.theme.spacing';
import { useScreenInfos } from '@unboared/utils.orientation';

export type LaunchPageProps = {
  onLaunch: () => void;
  props?: any;
};

export const LaunchPage = ({ onLaunch, ...props }: LaunchPageProps) => {
  const { normalize } = useNormalize();
  const screenInfos = useScreenInfos();

  let contentViewStyle;
  switch (screenInfos.type) {
    case 'laptop':
      contentViewStyle = {
        margin: normalize(space.massive),
        width: normalize(350),
        height: normalize(160),
      };
      break;

    default:
      contentViewStyle = {
        margin: normalize(space.larger),
        width: normalize(300),
        height: normalize(160),
      };
      break;
  }

  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      <View style={styles.container}>
        <ImageBackground
          resizeMode="cover"
          style={styles.background}
          source={ASSETS.backgroundGames}
        >
          <ContentView {...contentViewStyle}>
            <Logo size={30} />
            <Heading tx="screen.accessPage.title" />
            <Text tx="screen.common.description" />
            <Heading type="h3" tx="screen.launchPage.launchTitle" />
            <Button
              textPreset="medium"
              tx="screen.launchPage.launchBtn"
              onPress={onLaunch}
              style={{ width: 0.3 * contentViewStyle.width }}
            />
          </ContentView>
        </ImageBackground>
      </View>
    </ThemeProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  important: {
    color: accent_color.important,
  },
});

const ContentView = styled.View<{
  margin: number;
  width: number;
  height: number;
}>`
  margin-horizontal: ${(props) => props.margin};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-content: space-between;
`;
