import {
    BrowserRouter,
    Routes as RoutesReact,
    Route as RouteReact,
    useParams as useParamsReact,
    useSearchParams as useSearchParamsReact,
    useNavigate,
    Navigate as NavigateReact,
    useLocation as useLocationReact
} from "react-router-dom";


export const Router = (props) => (
    <BrowserRouter>
        {props.children}
    </BrowserRouter>
)

export const useLinkTo = useNavigate

export const Route = RouteReact
export const Routes = RoutesReact
export const useParams = useParamsReact
export const useSearchParams = useSearchParamsReact
export const Navigate = NavigateReact
export const useLocation = useLocationReact