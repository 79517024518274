import React from "react"
import { StyleSheet, View } from "react-native"

import { Button, Heading, Text, space, useNormalize } from "@unboared/base-ui.all"
import { useAuth } from "~/services/auth"
import { ActivityIndicator } from "~/components/common/loaders"
import { useGlobalPopup } from "~/components/screen/popup/global_popup"

const TX_LOGOUT = "screen.settingPage.myAccount.action.logout.popup."

export const LogoutConfirmation = () => {
    // Create styles
    const { normalize } = useNormalize()
    const styles = createStyles(normalize)

    // 
    const { loading, logout, auth } = useAuth()
    const close = useGlobalPopup(state => state.close)

    if (loading) {
        return <ActivityIndicator />
    }

    return (
        <View style={styles.container}>
            <Heading type='h2' tx={TX_LOGOUT + "title"} />
            <Text style={styles.content} tx={auth.isAnonymous ? TX_LOGOUT + "anonymousMsg" : TX_LOGOUT + "standardMsg"} />
            <View style={styles.buttonContainer}>
                <Button style={styles.button} tx={TX_LOGOUT + "validate"} onPress={() => {
                    close(); logout()
                }} />
                <Button style={styles.button} tx={TX_LOGOUT + "cancel"} onPress={close} />
            </View>
        </View>
    )
}

/** Stylesheet */
const createStyles = (normalize: (x: number) => number) => StyleSheet.create({
    container: {
        maxWidth: normalize(300),
    },
    content: {
        marginVertical: normalize(space.smaller),
    },
    buttonContainer: {
        marginTop: normalize(space.smaller),
        flexDirection: 'row', justifyContent: 'space-around'
    },
    button: { marginHorizontal: normalize(space.smaller) }
})
