import { db } from '~/services/firebase';
import {
    collection,
    orderBy,
    query,
    limit,
    getDocs,
    getDoc,
    doc,
} from 'firebase/firestore';
import { DEFAULT_LEVEL_NAME } from '~/config/const';

const getHighscore = async (docHighscore: any) => {
    let highscore = docHighscore.data();
    let user;
    try {
        let docUser = await getDoc(doc(db, 'users', docHighscore.id));
        user = {
            uid: docUser.id,
            username: docUser.get('username'),
            avatar: docUser.get('avatar_url'),
            color: docUser.get('color') || '#FF2453',
            score: highscore.score,
            date: highscore.date.toDate(),
        };
    } catch (err) {
        console.error(err);
        throw 'Cannot get user infos for highscores .';
    }
    return user;
};

const getHighscores = async (querySnapshot: any) => {
    let hsPromises: any[] = [];
    querySnapshot.forEach((doc) => {
        hsPromises.push(getHighscore(doc));
    });
    return await Promise.all(hsPromises);
};

export async function retrieveHighscores(gameID: string, total: number, group?: string | Array<string>, level?: string) {
    let levelName = level || DEFAULT_LEVEL_NAME;
    let limitValue = total <= 0 ? 10000 : total;

    let scoresRef = collection(db, 'games', gameID, 'highscores', levelName, 'users',);
    const q = query(scoresRef, orderBy('score', 'desc'), orderBy('date'), limit(limitValue));
    let highscores;
    try {
        let querySnapshot = await getDocs(q);
        highscores = await getHighscores(querySnapshot);
    } catch (err) {
        console.error('Cannot get highscores.');
        console.error(err);
    }

    return highscores;
}
