import { accent_color, Button, Heading, space, Text, useNormalize, useTranslate } from "@unboared/base-ui.all";
import { View } from "react-native";

import { useAuth } from "~/services/auth";
import { useGlobalPopup } from "~/components/screen/popup/global_popup";
import { ExtraSignInButtonWithLogo, PasswordInput } from "~/screens/common/signin";
import { useState } from "react";

export const Reauthenticate = ({ action, txAction = "screen.settingPage.myAccount.action.reauthenticate.popup.validate", onAuthenticated }: { action?: string, txAction?: string, onAuthenticated: () => void }) => {
    const auth = useAuth(state => state.auth)
    const errorMessage = useAuth(state => state.errorMessage)
    const { normalize } = useNormalize()
    const { translate } = useTranslate()

    return (
        <>
            <Heading type='h2' tx="screen.settingPage.myAccount.action.reauthenticate.popup.title" />
            <Text style={{ marginVertical: normalize(space.smaller) }} tx="screen.settingPage.myAccount.action.reauthenticate.popup.description" />
            <Text style={{ color: accent_color.warning }} text={translate(errorMessage)} />
            {(auth.isGoogle) && <ReauthenticateWithGoogle action={action} txAction={txAction} onAuthenticated={onAuthenticated} />}
            {(auth.isPassword) && <ReauthenticateWithPassword action={action} txAction={txAction} onAuthenticated={onAuthenticated} />}
        </>
    )
}

const ReauthMethod = ({ children }) => {
    const { normalize } = useNormalize()
    return (
        <View style={{ paddingVertical: normalize(space.smaller) }}>
            {children}
        </View>
    )
}

export const ReauthenticateWithGoogle = ({ action, txAction, onAuthenticated }: { action?: string, txAction?: string, onAuthenticated: () => void }) => {
    const { normalize } = useNormalize()
    const { reauthenticateWithGoogle } = useAuth()

    return (
        <ReauthMethod>
            <ExtraSignInButtonWithLogo
                tx="screen.settingPage.myAccount.action.reauthenticate.popup.google.title"
                logo="https://cdn-icons-png.flaticon.com/512/2965/2965278.png"
                logoSize={15}
                style={{ marginVertical: normalize(space.tiny) }}
                onPress={
                    () => reauthenticateWithGoogle().then(() => {
                        onAuthenticated()
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            />
        </ReauthMethod>
    )
}

export const ReauthenticateWithPassword = ({ action, txAction, onAuthenticated }: { action?: string, txAction?: string, onAuthenticated: () => void }) => {
    const { normalize } = useNormalize()
    const { translate } = useTranslate()

    const [password, setPassword] = useState("")
    const close = useGlobalPopup(state => state.close)
    const { auth, passwordErrorMessage, reauthenticateWithPassword, changePassword } = useAuth()

    const actionTxt = action || translate(txAction)


    const setPasswordAndHideError = (text: string) => {
        changePassword('')
        setPassword(text)
    }


    return (
        <ReauthMethod>
            <Text style={{ marginVertical: normalize(space.tiny) }} text={translate("screen.settingPage.myAccount.action.reauthenticate.popup.password.title", { email: auth.email })} />
            <PasswordInput
                password={password}
                onChangeValue={setPasswordAndHideError}
                passwordErrorMessage={translate(passwordErrorMessage)}
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <Button text={actionTxt} onPress={
                    () => reauthenticateWithPassword(auth.email, password).then(() => {
                        onAuthenticated()
                    }).catch((error) => {
                        console.log(error)
                    })
                } />
                <Button tx="screen.settingPage.myAccount.action.reauthenticate.popup.cancel" onPress={close} />
            </View>
        </ReauthMethod>
    )
}