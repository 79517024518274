import React, { useState } from 'react';
import { StyleSheet, StyleProp, ViewStyle, View, Image } from 'react-native';
import { motion } from 'framer-motion/dist/framer-motion';

import {
  StyledGameCard,
  StyledGameCardImage,
  StyledGameCardTitle,
} from './StyledGameCard';
import {
  Icon,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all';
import { useAuth } from '~/services/auth';

export type GameCardProps = {
  /**
   * the icon of the game
   */
  icon: string;

  /**
   * the title of the game
   */
  title: string;

  /**
   * the summary of the game
   */
  summary?: string;

  /**
   * if true, the card is active
   */
  active?: boolean;

  /**
   * if true, the card is disable
   */
  disable?: boolean;

  /**
   * the size of the card
   */
  size?: number;

  onPress?: () => void;

  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>;
};

/**
 * Unboared card component. This component display a clickable card of a game.
 * @param {dict} item - A dictionnary containing informations about the game.
 * @param {boolean} active - If the game is the current selected one.
 */
export const GameCard = ({ size = 90, ...props }: GameCardProps) => {
  const { auth } = useAuth();
  const {
    icon,
    title,
    summary,
    accessibility,
    active,
    style: styleOverride,
    onPress,
  } = props;

  const disable =
    accessibility === 'coming_soon' ||
    ((!auth.isLogin || auth.isAnonymous) && accessibility === 'free_with_account');

  const { normalize } = useNormalize();
  const normalizedSize = normalize(size);

  const height = 1.2 * normalizedSize,
    width = normalizedSize;

  const cardOpacity = disable ? 0.3 : 1.0;

  const cardStyles = [
    { width: width, height: height, cardOpacity },
    styleOverride,
  ];

  return (
    <motion.div
      animate={{ scale: active ? 1.1 : 1.0 }}
      transition={{ duration: 0.4 }}
    >
      <StyledGameCard active={active} style={cardStyles} onPress={onPress}>
        <StyledGameCardImage
          source={{ uri: icon }}
          size={normalizedSize}
          disable={disable}
          active={active}
        />
        <StyledGameCardTitle
          text={title}
          disable={disable}
          active={active}
        />
        {disable && accessibility === 'free_with_account' && (
          <View
            style={{
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              padding: normalize(0.1 * size),
            }}
          >
            <Icon name="fa-lock" color="white" size={20} />
            <Text
              style={{
                marginTop: normalize(space.tiny),
                textAlign: 'center',
              }}
              text="Want more games?"
            />
          </View>
        )}
        {accessibility === 'coming_soon' && (
          <View
            style={{
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              padding: normalize(0.1 * size),
            }}
          >
            <Icon name="fa-stopwatch" color="white" size={20} />
            <Text
              style={{
                marginTop: normalize(space.tiny),
                textAlign: 'center',
              }}
              text="Coming soon!"
            />
          </View>
        )}
      </StyledGameCard>
    </motion.div>
  );
};
