import { LoginType } from "./LoginType"
import { UserData } from "./UserData"

/**
 * This user type is used in the application.
 */
export class User {
    uid: string
    username: string
    avatar: string
    color?: string
    email?: string
    country?: string
    login_type?: LoginType
    isAdmin: boolean = false
    isDeveloper: boolean = false
    configs?: any
    otherAttributes?: any

    constructor(uid: string, username: string, avatar: string, color?: string) {
        this.uid = uid
        this.username = username
        this.avatar = avatar
        this.color = color
    }

    setAdmin(): User {
        this.isAdmin = true
        return this
    }

    setDeveloper(): User {
        this.isDeveloper = true
        return this
    }

    getLanguagePreference(): string {
        return this.configs?.language
    }

    getThemePreference(): string {
        return this.configs?.theme
    }

    static fromDatabase(userData: UserData) {
        let {
            uid,
            username,
            avatar_url,
            color,
            email,
            roles,
            country,
            login_type,
            configs,
            ...otherAttributes
        } = userData

        let user = new User(uid, username, avatar_url, color)
        user.login_type = login_type
        user.isAdmin = roles?.admin || false
        user.isDeveloper = roles?.developer || false
        user.email = email
        user.country = country
        user.configs = configs
        user.otherAttributes = otherAttributes
        return user
    }
};