import { Player } from "../player";
import { User } from "./User";
import { UserData } from "./UserData";

/**
 * The converter instance from / to player instance
 */
export const UserConverter = {
    toPlayer: (user: UserData): Player => {
        return {
            uid: user.uid,
            username: user.username,
            avatar: user.avatar_url,
            color: user.color,
        };
    },
    toDatabase: (user: User): UserData => ({
        uid: user.uid,
        username: user.username,
        avatar_url: user.avatar,
        color: user.color,
        email: user.email,
        country: user.country,
        login_type: user.login_type,
        configs: user.configs,
        roles: {
            admin: user.isAdmin,
            developer: user.isDeveloper,
        },
        ...user.otherAttributes
    }),
    fromDatabase: (userData: UserData): User => User.fromDatabase(userData)
};
