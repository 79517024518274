import { UserCredential } from 'firebase/auth';
import { ENV, isProdMode } from '~/config/env';

export type AuthStatus = 'RESET_PASSWORD' | 'SIGNIN' | 'SIGNUP';

/* The default authentification value */
export const INITIAL_STATE =
  !isProdMode()
    ? {
        status: '',
        auth: {
          uid: 'myUID',
          name: '',
          email: 'david@unboared.com',
          activated: 'true',
          isAnonymous: false,
          isLogin:false,
          isGoogle: false,
          isPassword: false,
          isFacebook: false,
          providerData: {},
        },
        email: '',
        password: '',
        loading: false,
        initialLoading: true,
        reauthenticating: false,
        errorCode: '',
        errorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
      }
    : {
        status: '',
        auth: null,
        email: '',
        password: '',
        loading: false,
        initialLoading: true,
        reauthenticating: false,
        errorCode: '',
        errorMessage: '',
        emailErrorMessage: '',
        passwordErrorMessage: '',
      };

export type Auth = {
  uid: string;
  name: string;
  email: string;
  activated: string;
};

export const initAuth = () => {
  return {
    uid: '',
    name: '',
    email: '',
    activated: true,
  };
};

export const getAuthMinified = (auth: any) => {
  const {
    uid,
    email,
    displayName: name,
    emailVerified: activated,
    isAnonymous,
    providerData,
  } = auth;

  let isGoogle = false,
    isFacebook = false,
    isPassword = false,
    isLogin = true;
  for (let provider of providerData) {
    switch (provider.providerId) {
      case 'password':
        isPassword = true;
        break;
      case 'google.com':
        isGoogle = true;
        break;
      case 'facebook.com':
        isFacebook = true;
        break;
    }
  }

  return {
    uid,
    name,
    email,
    activated,
    isAnonymous,
    isLogin,
    isGoogle,
    isPassword,
    isFacebook,
    providerData,
  };
};

/* The authentification state type  */
export type AuthState = {
  status: AuthStatus;
  auth: any;
  email: string;
  password: string;
  loading: boolean;
  initialLoading: boolean;
  reauthenticating: boolean;
  errorCode: string;
  errorMessage: string;
  emailErrorMessage: string;
  passwordErrorMessage: string;
  signup: (newletterSubscription: boolean) => void;
  login: (email: string, password: string) => void;
  loginWithPassword: (email: string, password: string) => void;
  loginWithGoogle: () => Promise<UserCredential>;
  loginAsGuest: () => void;
  loginWithoutAccount: () => void;
  loginSuccess: (auth: any) => void;
  loginFailure: (error: string, errorMessage: string) => void;
  loginEmailFailure: (error: string, errorMessage: string) => void;
  loginPasswordFailure: (error: string, errorMessage: string) => void;
  logout: () => void;
  deleteAccount: () => void;
  updatePassword: (password: string) => Promise<void>;
  onForgotPassword: () => void;
  onResetPassword: (
    password: string,
    actionCode: string,
    continueUrl: string,
    lang: string,
  ) => void;
  changeStatus: (status: AuthStatus) => void;
  changeEmail: (email: string) => void;
  changePassword: (password: string) => void;
  reauthenticate: (credential: UserCredential) => void;
  resetFields: () => void;
  resetErrorMessage: () => void;
  onAuthStateChanged: () => void;
  reauthenticateWithPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  reauthenticateWithGoogle: () => Promise<void>;
};
