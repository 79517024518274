import React, { useCallback, useState } from 'react';
import { useTheme } from 'rn-css';

import { useSound } from '@unboared/utils.sound';
import { usePlayers, useListener } from '@unboared/lib';
import { Theme } from '@unboared/base-ui.all';

import { NotEnoughPlayers, NotEnoughPlayersAlert2 } from '~/components/screen/alerts/not_enough_players';
import { log } from '~/config/env';
import { ComingSoonAlert } from '~/components/screen/alerts/coming_soon';
import { AccountLockAlert } from '~/components/screen/alerts/account_lock';
import { useAuth } from '~/services/auth';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import { logAnalyticsEvent } from '~/services/firebase';

/**
 * A "modern" sleep statement.
 *
 * @param ms The number of milliseconds to wait.
 */
// export const delay = (ms: number) =>
//   new Promise((resolve) => setTimeout(resolve, ms));

const checkNumPlayers = (minPlayers: number, numPlayers: number) => {
  return minPlayers <= numPlayers;
};

export const useValidate = (currentGame: any, actionOnPress: any) => {
  log.debug('In Home [ValidateGame]');

  const theme = useTheme() as Theme;
  const { auth } = useAuth();
  const open = useGlobalPopup((state) => state.open);

  const { getPlayers } = usePlayers();
  const players = getPlayers();

  const validate = useCallback(
    (game: any) => {
      
      // Log analytics events when try to access a game
      logAnalyticsEvent(`${game.id}/access`)

      if (game.accessibility === 'coming_soon') {
        open(<ComingSoonAlert gameName={game.title} size={60} />);
      } else {
        if ((!auth.isLogin || auth.isAnonymous)&& game.accessibility !== 'free') {
          open(<AccountLockAlert size={60} />);
        } else {
          if (checkNumPlayers(game.minPlayers, players.length)) {
            playValidateSound();
            actionOnPress(game);
          } else {
            open(
              <NotEnoughPlayersAlert2
                game={game.title}
                minPlayers={game.minPlayers}
              />,
            );
          }
        }
      }
    },
    [auth, players],
  );

  // Sound
  const [playValidateSound] = useSound(theme.song.validate);

  useListener(
    '__ok__',
    () => {
      validate(currentGame);
    },
    [players, validate, currentGame],
  );

  return { validate };
};
