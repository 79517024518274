export type Player = {

    /* The user unique identifier (in the database) */
    uid: string

    /* The username of the player */
    username: string

    /* The avatar of the player */
    avatar: string

    /* The player color */
    color?: string
}

export const INITAL_PLAYER = {
    username: '',
    avatar: '',
}