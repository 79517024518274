import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import styled from 'rn-css';

import { usePlayers } from '@unboared/lib';
import { PlayerEdit } from '@unboared/design.all';
import { space, Text, useNormalize } from '@unboared/base-ui.all';
import { ProfileSetting } from '~/components/common/view/profile_setting';
import { FullscreenButton } from '~/components/common/system_actions/fullscreen';
import { ConditionalConnexionInfos } from '~/components/gamepad/view/connexion_infos';
import { ConditionalGlobalPopup } from '~/components/gamepad/view/global_popup';
import { useAuth } from '~/services/auth';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';
import { OneTextTwoButtonsCenteredPopupLayout } from '~/components/common/layout/popup';
import LockImg from '~/assets/img/Lock.png';
import { UserAPI } from '~/services/user';
import { logAnalyticsEvent } from '~/services/firebase';

export type InPlatformLayoutProps = {
  /**
   * the children nodes.
   */
  children: any;
};

export const InPlatformLayout = ({ children }: InPlatformLayoutProps) => {
  const { normalize } = useNormalize();
  const { auth } = useAuth();
  const { open, close } = useGlobalPopup();

  // const {user}= useActiveUser()
  const { isMaster, loadPlayer, getPlayer, getHostID } = usePlayers();
  const me = getPlayer();
  const getMoreGames = () => {
    window.location.reload();
    // linkTo('/signup');
  };

  const [showSetting, setShowSetting] = useState(false);

  const toggleSetting = () => {

    logAnalyticsEvent("platform/gamepad/open_settings")

    if (auth.isLogin) {
      setShowSetting((prev) => !prev);
    } else {
      open(
        <View style={{ transform: [{ scale: 0.5 }] }}>
          <OneTextTwoButtonsCenteredPopupLayout
            sizeImg={60}
            src={LockImg}
            scale={1.5}
            txTitle="common.popupCreateAccount.titleEditProfile"
            txButton1="common.popupCreateAccount.btn1"
            txButton2="common.popupCreateAccount.btn2"
            onPressBtn1={getMoreGames}
            onPressBtn2={close}
            activeButton={1}
          >
            <>
              <Text
                style={{ textAlign: 'center' }}
                tx="common.popupCreateAccount.description"
              />
              <Text
                style={{ textAlign: 'center' }}
                tx="common.popupCreateAccount.feature1"
              />
              <Text
                style={{ textAlign: 'center' }}
                tx="common.popupCreateAccount.feature2"
              />
              <Text
                style={{ textAlign: 'center' }}
                tx="common.popupCreateAccount.feature3"
              />
            </>
          </OneTextTwoButtonsCenteredPopupLayout>
        </View>,
      );
    }
  };

  const onSubmit = (newPlayer) => {
    loadPlayer(newPlayer);
    UserAPI.updateProfile(newPlayer.uid, newPlayer.avatar, newPlayer.username)
    toggleSetting();
  };

  if (showSetting) {
    return (
      <ProfileSetting player={me} onSubmit={onSubmit} onClose={toggleSetting} />
    );
  }

  // if (showSetting && !user.isLogin) {
  //   return <></>
  // }

  return (
    <View style={{ flex: 1, height: '100%', width: '100%' }}>
      <TopLeftView marginSize={normalize(space.smaller)}>
        <PlayerEdit
          size={30}
          username={me.username}
          avatar={me.avatar}
          color={me.color}
          master={isMaster()}
          onPress={toggleSetting}
        />
      </TopLeftView>
      {Platform.OS == 'web' && (
        <TopRightView marginSize={normalize(space.smaller)}>
          <FullscreenButton size={20} />
        </TopRightView>
      )}
      <ConditionalGlobalPopup />
      <ConditionalConnexionInfos />
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  );
};

const TopLeftView = styled.View<{ marginSize: number }>`
  position: absolute;
  flex-direction: row;
  top: ${(props) => props.marginSize};
  left: ${(props) => props.marginSize};
  z-index: 99;
`;

const TopRightView = styled.View<{ marginSize: number }>`
  position: absolute;
  top: ${(props) => props.marginSize};
  right: ${(props) => props.marginSize};
  z-index: 99;
`;
