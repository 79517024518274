import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy as fbOrderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useLanguage } from '@unboared/utils.language';
import { db } from '~/services/firebase';
import fuzzysort from 'fuzzysort';

export const gamesAPI = {
  getGames: (tags: Array<string> = [], lang: string = 'en') => {
    const collectionRef = collection(db, 'games');
    const promiseGames = new Promise((resolve, reject) => {
      getDocs(collectionRef)
        .then((querySnapshot) => {
          let documents: Game[] = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.translation && data.translation[lang]) {
              data = { ...data, ...data.translation[lang] };
            }
            data = { ...data, id: doc.id };
            if (tags.length > 0) {
              if (data.tags) {
                const filteredArray = data.tags.filter((value) =>
                  tags.includes(value),
                );
                // console.log(data.id);
                // console.log(data.tags);
                // console.log(filteredArray);
                if (filteredArray.length > 0) {
                  documents.push(data);
                }
              }
            } else {
              documents.push(data);
            }
          });

          resolve(documents);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promiseGames;
  },
  getGamesByName: (name: string, max = 20, lang = 'en') => {
    const collectionRef = collection(db, 'games');
    const promiseGames = new Promise((resolve, reject) => {
      getDocs(collectionRef)
        .then((querySnapshot) => {
          let documents: Game[] = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            if (data.tags.includes('atari7800') || data.tags.includes('unboared')) {
              if (data.translation && data.translation[lang]) {
                data = { ...data, ...data.translation[lang] };
              }
              data = { ...data, id: doc.id };
              documents.push(data);
            }
          });

          const results = fuzzysort.go(name, documents, {
            all: true,
            key: 'title',
          });
          resolve(results.map((doc) => doc.obj));
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promiseGames;
  },
  getGame: (id: string, lang: string = 'en') => {
    const docRef = doc(db, 'games', id);
    const promiseGame = new Promise((resolve, reject) => {
      getDoc(docRef)
        .then((item: any) => {
          let data = item.data();
          if (data.translation && data.translation[lang]) {
            data = { ...data, ...data.translation[lang] };
          }
          data = { ...data, id: item.id };
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promiseGame;
  },
  renameGame: async (id: string) => {
    const docRef = doc(db, 'games', id);
    let data;
    getDoc(docRef).then((item) => {
      data = item.data();
      setDoc(doc(db, 'games', data.short), data);
    });
  },
};

export const useGame = (id: string) => {
  const { language } = useLanguage();
  const [game, setGame] = useState();

  useEffect(() => {
    const loadGame = async (id: string) => {
      gamesAPI
        .getGame(id, language)
        .then(setGame)
        .catch((error) => {
          console.log(error);
        });
    };
    loadGame(id);
  }, [id, language]);

  return game;
};

export const useGames = (tags = []) => {
  const { language } = useLanguage();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const loadGames = async () => {
      gamesAPI
        .getGames(tags, language)
        .then((games) => {
          let freeGames = [],
            freeWithAccount = [],
            commingSoon = [];
          for (let g of games) {
            switch (g.accessibility) {
              case 'free_with_account':
                freeWithAccount.push(g);
                break;
              case 'free':
                freeGames.push(g);
                break;
              case 'coming_soon':
                commingSoon.push(g);
                break;
              default:
                commingSoon.push(g);
                break;
            }
          }

          setItems([...freeGames, ...freeWithAccount, ...commingSoon]);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadGames();
  }, [language]);

  return items;
};