import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import { DEFAULT_LEVEL_NAME } from '~/config/const';
import { db } from '~/services/firebase';
import { retrieveHighscores } from '~/services/scores_api';

export const useHighscores = (gameID?: string, limit: number = -1, level: string = DEFAULT_LEVEL_NAME) => {
  const [highscores, setHighscores] = useState();

  const loadHighscores = (gameID: string, limit: number, level: string) => {
    retrieveHighscores(gameID, limit, undefined, level)
      .then((result) => {
        setHighscores(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const highscoresListener = (gameID: string, limit: number, level: string) => {
    const docRef = collection(db, 'games', gameID, 'highscores', level, 'users');

    const unsub = onSnapshot(docRef, (doc) => {
      loadHighscores(gameID, limit, level);
    });

    return unsub;
  }

  useEffect(() => {
    if (gameID) {
      // listen to highscore changes
      const unsub = highscoresListener(gameID, limit, level)

      // get highscores
      loadHighscores(gameID, limit, level);
      return unsub;
    }
  }, [gameID, limit, level]);

  return highscores;
};
