import React from 'react';
import { StyleSheet, View } from 'react-native';

import {
  useNormalize,
  Text,
  Heading,
  space,
} from '@unboared/base-ui.all';

import { useMargin } from './hooks/useMargin';
import { log } from '~/config/env';
import { GoogleSignIn } from './components/google';
import { GuestSignIn } from './components/guest';
import { useAuth } from '~/services/auth';
import { ActivityIndicator } from '~/components/common/loaders';
import { Separator } from '../separator';
import { useAnalyticsAccessEvent } from '~/services/firebase';
import { EmailPasswordButton } from './components/email_password_button';
import { CreateAccountLink } from './components/create_account_link';

/**
 * Sign in page.
 */
export const SignIn = () => {
  log.debug('Mount SignIn');
  useAnalyticsAccessEvent('platform/signin')

  const { normalize } = useNormalize();
  const { mediumMargin } = useMargin()
  const loading = useAuth((state) => state.loading);
  const styles = makeStyles(normalize)

  return (
    <View style={[styles.flexCenter, styles.container]}>
      <View style={styles.flexCenter}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.content}>
            <Heading
              type="h2"
              style={{ alignSelf: 'center' }}
              tx="common.signup.title"
            />
            <Text
              style={{ marginBottom: normalize(space.small), fontWeight: 300 }}
              tx="common.signup.description"
            />
            <GoogleSignIn />
            <EmailPasswordButton />
            <View style={mediumMargin}>
              <Separator />
            </View>
            <GuestSignIn />
            <CreateAccountLink />
          </View>
        )}
      </View>
    </View>
  );
};

const makeStyles = (normalize: any) => StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  flexCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: normalize(250),
    justifyContent: 'center',
    textAlign: 'center'
  },
})

export default SignIn;
