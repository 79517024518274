import React from "react"
import { StyleSheet, ViewStyle } from "react-native"

import { Branding } from "~/components/common/branding"
import { DarkImageContainer } from "~/components/common/layout/dark_image_container"

export const AuthContainer = ({ children }: any) => (
    <DarkImageContainer style={[styles.flexCenter, styles.container]} noBackground>
        <Branding />
        {children}
    </DarkImageContainer>
)


const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    flexCenter: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    }
})