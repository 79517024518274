import React, { useEffect } from 'react';
import { StatusBar, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { i18n, Theme, ThemeProvider, unboaredTheme } from '@unboared/base-ui.all';
import { usePreferedLanguage } from '@unboared/utils.language';
import { useTheme } from '@unboared/utils.theme';

import { isProdMode, log } from '~/config/env';

import { useAuthManager } from '~/services/auth';
import { AppNavigator } from '~/navigation/AppNavigator';
import { DevConfiguration } from '~/components/common/dev_configuration';
import { NoSleepView } from '~/components/common/view/no_sleep';

import { en, fr } from '~/config/translations';

// Setup the translations
i18n.translations = { en, fr };

/**
 * This is the root component of our app.
 */
function App() {
  log.debug('In App');

  const insets = useSafeAreaInsets();

  useEffect(() => {
    StatusBar.setHidden(false);
  }, []);

  usePreferedLanguage();
  useAuthManager();

  // const colorScheme = useColorScheme()
  // const theme = useTheme((state) => state.theme);

  // now, we're ready to render the app
  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      <NoSleepView />
      <View style={styles(insets, unboaredTheme.dark).container}>
        <AppNavigator />
        {!isProdMode() && <DevConfiguration />}
      </View>
    </ThemeProvider>
  );
}

const styles = (insets: any, theme: Theme) => StyleSheet.create({
  container: {
    position: 'fixed',
    paddingTop: insets.top,
    paddingLeft: insets.left,
    paddingBottom: insets.bottom,
    paddingRight: insets.right,
    height: '100%',
    width: '100%',
    backgroundColor: theme.backgroundColor,
  }
})

export default App;
