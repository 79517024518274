import React, { useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { useTranslate, useTheme, space, Theme, useNormalize, accent_color } from '@unboared/base-ui.all';
import { Text, Icon, Heading, TextInput, Button, PortraitMobileLayout } from '@unboared/base-ui.all';
import { AvatarSelection } from '@unboared/design.all';

import { sampleAvatar, CODE_UNGUYS } from '~/config/const';
import { Player } from '~/model/player';
import { useAuth } from '~/services/auth';

export type BaseProfileSettingProps = {
  player: Player;
  submitTxt: string;
  labelTxt: string;
  onSubmitted?: (player: Player) => void;
  onClose?: () => void;
  create?: boolean;
};

export const BaseProfileSetting = ({
  player,
  submitTxt,
  labelTxt,
  onSubmitted,
  onClose,
  create,
}: BaseProfileSettingProps) => {
  const { auth } = useAuth()

  // Use theme and translation
  const theme = useTheme();
  const { translate } = useTranslate();

  // Create styles
  const { normalize } = useNormalize();
  const styles = createStyles(normalize)

  // Current local avatar
  const [localAvatar, setLocalAvatar] = useState<string>(player.avatar);

  // The seed field
  const [seed, setSeed] = useState('');

  // The username field value
  const [localUsername, setLocalUsername] = useState(player.username);

  // The error message
  const [error, setError] = useState('');

  const { logout } = useAuth();

  const updateSeed = (seed: string) => {
    setSeed(seed);
    if (CODE_UNGUYS.has(seed)) {
      setLocalAvatar(CODE_UNGUYS.get(seed));
    }
  };

  const updateEmail = (email: string) => {
    setSeed(email)
  }

  const updateURL = (url: string) => {
    setSeed('');
    setLocalAvatar(url);
  };

  const handleSubmit = () => {
    if (create && auth.isAnonymous && !seed.includes('@')) {
      setError(translate("gamepad.profileSetting.error.wrong_email"))
    }
    else {
      if (onSubmitted) {
        onSubmitted({ ...player, username: localUsername, avatar: localAvatar, email: auth.isAnonymous ? seed : auth.email });
      }
    }
  };

  const input_styles = [styles.input, { marginBottom: normalize(space.small) }];

  return (
    <View style={{ flex: 1, alignSelf: 'stretch' }}>
      <PortraitMobileLayout
        header={<Heading>{translate('gamepad.profileSetting.title')}</Heading>}
        flex={{ header: 20, content: 80 }}
      >
        <View style={input_styles}>
          <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
            {translate('gamepad.profileSetting.avatarLabel')}
          </Heading>
          <AvatarSelection
            current={localAvatar}
            setAvatar={updateURL}
            color={player.color}
            generator={sampleAvatar}
            size={80}
          />
          <View style={styles.codeContainer}>
            <Text preset='small' text={create && auth?.isAnonymous ? "Email" : "Code :"} />
            {create && auth?.isAnonymous ?
              (<TextInput
                containerStyle={styles.textInputContainer}
                style={{ minWidth: '20%' }}
                text={seed}
                onChangeText={updateEmail}
                placeholder="e-mail"
              />) :
              (<TextInput
                containerStyle={styles.textInputContainer}
                style={{ minWidth: '20%' }}
                text={seed}
                onChangeText={updateSeed}
                placeholder="code unguys"
              />)
            }
          </View>
        </View>
        <View style={input_styles}>
          <Heading type="h3" style={{ margin: normalize(space.smaller) }}>
            {labelTxt}
          </Heading>
          <TextInput
            minLength={3}
            maxLength={12}
            text={localUsername}
            placeholder={translate(
              'gamepad.profileSetting.usernamePlaceholder',
            )}
            onChangeText={setLocalUsername}
          />
        </View>
        <View style={[input_styles, styles.buttons]}>
          {onClose && (
            <Button preset="secondary" icon="back" onPress={onClose} />
          )}
          <Button
            preset="primary"
            text={submitTxt}
            onPress={handleSubmit}
            style={{ flex: 0.8 }}
          />
        </View>
        {error && <Text style={{ color: accent_color.error }} text={error} />}
        {!create && (
          <Pressable
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
            onPress={logout}
          >
            <Icon name="md-logout" size={15} color={theme.color.primary} />
            <Text
              style={{
                color: theme.color.primary,
                marginVertical: normalize(space.small),
              }}
              tx="screen.settingPage.myAccount.action.logout.title"
            />
          </Pressable>
        )}
      </PortraitMobileLayout>
    </View>
  );
};

const createStyles = (normalize: (x: number) => number) => StyleSheet.create({
  input: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttons: {
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-evenly',
  },
  codeContainer: {
    width: '90%',
    marginTop: normalize(space.smaller),
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  textInputContainer: {
    borderWidth: 2,
    marginLeft: normalize(space.tiny),
    minWidth: '20%',
    maxWidth: '60%'
  }
});
