import { User } from "~/model/user";
import { Player } from "~/model/player";

/* The active user state type  */
export type UserState = {
  user: User;
  loading: boolean;
  errorMessage: string;
  createUser: (uid: string, player: Player) => void;
  loadUserData: (uid: string) => void;
  setUser: (user: any) => void;
  setRandomUser: () => void;
};

/* The default user state value */
export const initialUserState = {
  user: undefined,
  loading: true,
  errorMessage: '',
};
