import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useFonts } from 'expo-font'

import { printEnv } from '~/config/env';
import { useAnalyticsAccessEvent } from '~/services/firebase';
import { getBrowserName, getDeviceInfos, getOS } from '~/utils/device_infos';

import MainApp from './src/app';

function App() {
  printEnv();
  useAnalyticsAccessEvent(`browser/${getBrowserName()}`)
  useAnalyticsAccessEvent(`OS/${getOS()}`) 

  // Load fonts
  const [fontsLoaded] = useFonts({
    Arco: require('./src/assets/fonts/ARCO.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }

  return (
    <SafeAreaProvider>
      <MainApp />
    </SafeAreaProvider>
  );
}

export default App;
