import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import styled from 'rn-css';

import { usePlayers, useScene } from '@unboared/lib';
import { Theme } from '@unboared/base-ui.theme.themes';
import { useFullscreen } from '@unboared/utils.fullscreen';

import { InGameLayout } from '~/components/gamepad/layout/in_game';
import { InPlatformLayout } from '~/components/gamepad/layout/in_platform';

import { isProdMode } from '~/config/env';
// import { GamepadComponent } from '~/config/games';
import { OpenFullscreenView } from '~/components/gamepad/view/open_fullscreen_view';

import { InPlatformGamepad } from './in_platform_app/in_platform_app';
import { GamepadInGamePage } from '~/screens/scenario/in_game/gamepad';
import { UnboaredPageLoader } from '~/components/common/loaders';
import { useTheme } from '@unboared/base-ui.all';
import { GlobalPopup } from '~/components/screen/popup/global_popup';
import { iOS } from '~/utils/device_infos';

export const GamepadPlatformPage = () => {
  const theme = useTheme();

  const { getScreenIDs } = usePlayers();
  const { getScene } = useScene();
  const scene = getScene();

  if (!scene || getScreenIDs().length === 0) {
    return <UnboaredPageLoader infos={'Screen connection...'} />;
  }

  const isInGame = scene !== 'in_game';
  const CurrentGamepad = isInGame ? InPlatformGamepad : GamepadInGamePage;
  const CurrentLayout = isInGame ? InPlatformLayout : InGameLayout;

  return (
    <>
      <OpenFullscreenPage />
      <GlobalPopup />
      <CurrentLayout>
        <CurrentGamepad />
      </CurrentLayout>
    </>
  );
};

const OpenFullscreenPage = () => {
  const { isFullscreen } = useFullscreen();

  if (isProdMode() && Platform.OS == 'web' && !iOS() && !isFullscreen) {
    return (
      <ViewOver>
        <OpenFullscreenView />
      </ViewOver>
    );
  }
  return <></>;
};

const ViewOver = styled.View`
  position: absolute;
  background-color: ${({ theme }) => (theme as Theme).backgroundColor};
  top: 0;
  left: 0;
  z-index: 99;
  elevation: 99;
  width: 100%;
  height: 100%;
`;
