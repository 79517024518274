import { getBrowserName, getDeviceType, getOS } from "~/utils/device_infos";
import { GeolocationAPI } from "~/services/geolocation";
import { GeolocationData } from "~/model/geolocation";

export type DeviceType = 'Desktop'
    | 'Tablet'
    | 'Smartphone'
    | 'Feature Phone'
    | 'Smart-TV'
    | 'Robot'
    | 'Other non-Mobile'
    | 'Other Mobile'

export type Browser = 'Mozilla Firefox'
    | 'Samsung Internet'
    | 'Opera'
    | 'Microsoft Edge (Legacy)'
    | 'Microsoft Edge (Chromium)'
    | 'Google Chrome or Chromium'
    | 'Apple Safari'
    | 'Other Browser'

export type OS = 'Mac OS'
    | 'iOS'
    | 'Windows'
    | 'Android'
    | 'Linux'
    | 'Other OS'

export type DeviceInfos = {
    name: string,
    geolocation: GeolocationData,
    ip: string,
    type: DeviceType,
    os: OS,
    browser: Browser
}

export const getDeviceInfos = async (): Promise<DeviceInfos> => {
    let location: GeolocationData = await GeolocationAPI.getLocation()
    let type = getDeviceType()
    let os = getOS()
    let browser = getBrowserName()
    return {
        geolocation: location,
        ip: location.ip,
        type: type,
        name: `${os}-${browser}-${location.city}-${location.state}-${location.country}`,
        os: os,
        browser: browser,
    }
}