import React, { useEffect } from 'react';
import { NativeScrollEvent, ScrollView } from 'react-native';

import { base_color } from '@unboared/base-ui.all';
import { useNormalize } from '@unboared/utils.scale';

import PositionScore from './score/PositionScore';
import { useVerticalScroll } from '~/hooks/useVerticalScroll';

const SPEED = 60; // px per second
const UPDATE_RATE = 100; // ms between each scroll
const PX_PER_SCROLL = 10 * (SPEED / UPDATE_RATE);
const WAITING_TIME = 2000; // in seconds

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: NativeScrollEvent) => {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

/**
 * Displays a scrolling view with highscores for other players.
 */
export const ScrollingHighscores = ({ highscores }: any) => {
  const { normalize } = useNormalize();

  /* Get vertical scrolling functionalities */
  const { scrollRef, offset, scrollDown, restart } = useVerticalScroll()

  /* Start scrolling after X secondes */
  useEffect(() => {
    if (offset == 0) {
      setTimeout(() => scrollDown(PX_PER_SCROLL), WAITING_TIME);
    }
  }, [offset]);

  /* Scroll down continuously */
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (offset > 0) {
      interval = setTimeout(() => scrollDown(PX_PER_SCROLL), UPDATE_RATE);
    }
    return () => clearInterval(interval);
  }, [offset]);


  if (!scrollRef) {
    return <></>;
  }

  return (
    <ScrollView
      ref={scrollRef}
      style={{ maxHeight: normalize(3 * 60) }}
      onScroll={({ nativeEvent }) => {
        if (isCloseToBottom(nativeEvent)) setTimeout(restart, WAITING_TIME);
      }}
      scrollEventThrottle={400}
      showsHorizontalScrollIndicator={false}
    >
      {highscores.slice(3).map((player: any, i: number) => (
        <PositionScore
          position={i + 4}
          player={player}
          score={player.score}
          color={base_color.dark_secondary}
          size={normalize(20)}
          width={350}
        />
      ))}
    </ScrollView>
  );
}
