import React, { useRef } from 'react';
import { Platform, StyleSheet } from 'react-native';
import NoSleepVideo from '~/assets/video/4hours.mp4';

/**
 * This component is used to prevent the device from going into sleep mode.
 * The solution found for this is to play a 4 hours video in the background.
 * Loop the same video does'nt work on old version of iOS. 
 */
export const NoSleepView = () => {
  const videoRef = useRef();

  if (Platform.OS === 'web') {
    return (
      <video ref={videoRef} style={styles.video} src={NoSleepVideo} width="10" height="10" autoPlay playsInline webkit-playsinline="true" muted></video>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  video: {
    position: 'fixed',
    right: 0,
    bottom: 0,
  }
})