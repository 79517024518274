import { API_KEY, requestOptions } from "./const";
import { GeolocationData, GeolocationConverter } from "~/model/geolocation";

export const GeolocationAPI = {

    /**
     * Returns the geolocation of the computer.
     * @param ip the ip address of the computer 
     */
    async getLocation(ip: string = ""): Promise<GeolocationData> {
        let response = await fetch(`https://api.geoapify.com/v1/ipinfo?${ip ? "ip=" + ip : ""}&apiKey=${API_KEY}`, requestOptions)
        let result = await response.json()
        return {
            ...GeolocationConverter.fromGeoapify(result)
        }
    }
}
