import React from 'react';
import { ImageBackground, View, ViewStyle } from 'react-native';
import { ThemeProvider, unboaredTheme } from '@unboared/base-ui.all';
import { ASSETS } from '~/config/const';

export type DarkImageContainerProps = {
  /**
   * the style of the container
   */
  style?: ViewStyle | ViewStyle[];

  /**
   * children components
   */
  children?: any;

  /**
   * if set, do not display background image
   */
  noBackground?: boolean;
};

export function DarkImageContainer({
  style,
  children,
  noBackground,
}: DarkImageContainerProps) {
  return (
    <ThemeProvider theme={unboaredTheme.dark}>
      {noBackground ? (
        <View style={style}>{children}</View>
      ) : (
        <ImageBackground
          resizeMode="cover"
          style={style}
          source={ASSETS.backgroundGames}
        >
          {children}
        </ImageBackground>
      )}
    </ThemeProvider>
  );
}
