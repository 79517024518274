import React from "react";
import { Pressable } from "react-native";

import { Text, accent_color, useTranslate } from "@unboared/base-ui.all";

import { useMargin } from "../hooks/useMargin";
import { useAuth } from "~/services/auth";
import { useLinkTo } from "~/navigation/router";

/**
 * The link to the signup page.
 */
export const CreateAccountLink = () => {
    const { translate } = useTranslate();
    const { largeMargin } = useMargin();

    const linkTo = useLinkTo();
    const resetFields = useAuth((state) => state.resetFields);

    return (
        <Pressable
            onPress={() => {
                resetFields();
                linkTo('/signup');
            }}
        >
            <Text style={{ ...largeMargin, fontWeight: 'bold', alignSelf: 'center' }}>
                {translate('common.signin.noAccount.message')}{' '}
                <Text
                    style={{ color: accent_color.important }}
                    tx="common.signin.noAccount.createAccount"
                />
            </Text>
        </Pressable>
    );
};