import { db } from '~/services/firebase';
import { collection, orderBy, query, limit, getDocs, doc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { DeviceInfos, getDeviceInfos } from '~/utils/device_infos';

export const DevicesInfosApi = {

    /**
     * Get informations about devices that were connected to the account.
     * @param uid the user id
     * @param total the limit of devices infos
     * @returns device informations
     */
    async getDevicesInfos(uid: string, total: number): Promise<Array<DeviceInfos>> {
        let devicesInfos: any = [];

        /* Set document limitation */
        let limitValue = total <= 0 ? 10000 : total;

        /* Prepare query */
        let devicesRef = collection(db, 'users', uid, 'devices');
        const q = query(devicesRef, orderBy('last_connection', 'desc'), limit(limitValue));

        /* Call query */
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc: any) => {
            devicesInfos.push(doc.data());
        });

        return devicesInfos;
    },

    /**
     * Update devices informations
     * @param uid the user id
     */
    async updateDevicesInfos(uid: string) {
        let deviceInfos: any = await getDeviceInfos();
        deviceInfos.last_connection = serverTimestamp()

        /* Get device document ref */
        let deviceRef = doc(db, 'users', uid, 'devices', deviceInfos.name);

        /* Call query */
        await setDoc(deviceRef, deviceInfos)
    },

    /**
     * This function will request logout for a specific device.
     * This implies that the device will logout from unboared platform
     * at the next connection. 
     * @param uid the user id
     * @param deviceID the device id
     */
    disconnect(uid: string, device_name: string) {
        /* Get device document ref */
        let deviceRef = doc(db, 'users', uid, 'devices', device_name);
        updateDoc(deviceRef, {
            request_logout: true
        })

    }
}
