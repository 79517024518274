import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { useScreenScale } from '@unboared/base-ui.all';
import { UnboaredGame, CommunicationConfig } from '@unboared/lib';

import { log } from '~/config/env';
import {
  DEFAULT_SCENE,
  GAME_NAME,
  getRoomURL,
  getSocketServer,
} from '~/config/const';

import { UnboaredPageLoader } from '~/components/common/loaders';
import { ScreenPlatformApp } from './screen_platform_app';

import {
  logAnalyticsEvent,
  useAnalyticsAccessEvent,
} from '~/services/firebase';
import { useAuth, getAuthType } from '~/services/auth';
import { PlatformApi } from '~/config/api/PlatformApi';

var host =
  Platform.OS === 'web'
    ? window.location.protocol + '//' + window.location.host
    : 'https://play.unboared.com/';

export const UnboaredScreenApp = () => {
  useAnalyticsAccessEvent('platform/screen');

  const { auth } = useAuth();
  useEffect(() => {
    if (auth) {
      logAnalyticsEvent(`platform/screen/auth/${getAuthType(auth)}`);
    }
  }, [auth]);
  
  return <UnboaredScreenAppContent />;
};

const UnboaredScreenAppContent = () => {
  log.debug('In ScreenApp');
  useScreenScale();

  return (
    <UnboaredGame
    api={new PlatformApi(DEFAULT_SCENE, "screen", getSocketServer()).init()}
      name={GAME_NAME}
      initialScene={DEFAULT_SCENE}
      loader={<UnboaredPageLoader infos="Connexion to the server..." />}
      config={CommunicationConfig.getSocketIO('screen', getSocketServer(), {
        url: getRoomURL(host, '{sessionID}'),
      })}
    >
      <ScreenPlatformApp />
    </UnboaredGame>
  );
};
