import React from "react";
import { useTranslate } from "@unboared/base-ui.all";
import { sampleAvatar } from "~/config/const";
import { Player } from "~/model/player";
import { BaseProfileSetting } from "./base_profile_setting";

export type UserAuthenticationProps = {
    player: Player;
    onSubmit: (player: Player) => void;
}

export const UserAuthentication = ({ player, onSubmit }: UserAuthenticationProps) => {
    const randomAvatar = sampleAvatar();
    const { translate } = useTranslate();

    return (
        <BaseProfileSetting
            player={{ ...player, avatar: randomAvatar }}
            labelTxt={translate('gamepad.profileSetting.create.usernameLabel')}
            submitTxt={translate('gamepad.profileSetting.create.submitText')}
            onSubmitted={onSubmit}
            create
        />
    );
};
