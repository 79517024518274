import { GeolocationData } from "./GeolocationData";


/**
 * Convert a data structure for the application from/to a data structure for the database.
 * Here we convert question types.
 */
export const GeolocationConverter = {
    fromGeoapify: (data: any): GeolocationData => ({
        country: data.country.name,
        iso_code: data.country.iso_code,
        state: data.state.name,
        city: data.city.name,
        location: data.location,
        ip: data.ip
    })
}