import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import { useScale } from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

/* Navigation utils */
import { Navigate, Route, Router, Routes, useLocation } from './router';
import { useLocationMemo } from './locationMemo';

/* Container of the auth pages */
import { AuthContainer } from './AuthContainer';

/* Import screends for the auth flow */
import { SignIn } from '~/screens/common/signin2';
import { SignUp } from '~/screens/common/signup2';
import { Auth } from '~/screens/common/auth';
import { EmailVerification } from '~/screens/common/email_verification';
import { AccessPage } from '~/screens/gamepad/access';
import SignUpEmail from '~/screens/common/signup2/signup_email';
import SignInEmail from '~/screens/common/signin2/signin_email';

/**
 * Authentification flow.
 */
const AuthRouter = () => {
  const { type } = useScreenInfos();
  const { setScale } = useScale();

  useEffect(() => {
    // Change scale
    setScale(type === 'mobile' ? 1.2 : 1);
  }, []);

  return (
    <Router>
      <AuthRoutes />
    </Router>
  );
};

const titles = {
  '/': 'Unboared',
  '/login': 'Login • Unboared',
  '/signup': 'Signup • Unboared',
  '/auth': 'Authentication • Unboared',
  '/gamepad':'Join Session • Unboared',
  '/auth/mail': 'Email Verification • Unboared',
};

const AuthRoutes = () => {
  const location = useLocation();
  const setLocation = useLocationMemo((state) => state.setLocation);

  useEffect(() => {
    setLocation(location);
  }, []);

  useEffect(() => {
    if (Platform.OS == 'web') {
      document.title = titles[location.pathname] ?? 'Unboared';
    }
  }, [location]);

  return (
    <AuthContainer>
      <Routes>
        <Route path="/" element={<Navigate to="/signup" />} />
        <Route path="login" element={<SignIn />} />
        <Route path="login/email" element={<SignInEmail />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signup/email" element={<SignUpEmail />} />
        <Route path="gamepad" element={<AccessPage />} />
        <Route path="auth" element={<Auth />}/>
        <Route path="auth/mail" element={<EmailVerification />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </AuthContainer>
  );
};

export default AuthRouter;
