import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTheme } from '@unboared/utils.theme';

import { api } from '@unboared/lib';
import { GameRunner } from '~/components/screen/view/game_runner';
import { useGame } from '~/services/games_api/games_api';
import { UnboaredPageLoader } from '~/components/common/loaders';
import { log, URL_TEST_GAME_GAMEPAD } from '~/config/env';
import { TEST_GAME } from '~/config/const';

export const GameGamepad = ({ gameID }: { gameID: string }) => {
  const currentGame = useGame(gameID) as any;

  if (!currentGame) {
    return <UnboaredPageLoader infos="Loading game" />;
  }

  return (
    <GameRunner
      title={currentGame.id || 'Game'}
      src={TEST_GAME ? TEST_GAME.gamepad : currentGame.gamepad_url}
      version={currentGame.ub_version || 'latest'}
    />
  );
};

const useGameID = () => {
  const [gameID, setGameID] = useState();

  useEffect(() => {
    function loadCurrGame() {
      const id = api().getCustomDeviceStateProperty(
        api().getHostID(),
        'gameID',
      );
      setGameID(id);
    }
    loadCurrGame();
  }, []);
  return gameID;
};

export const GamepadInGamePage = () => {
  log.debug('In Game');

  const theme = useTheme((state) => state.theme);
  const gameID = useGameID();

  if (!gameID) {
    return <UnboaredPageLoader infos="Loading game" />;
  }

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <GameGamepad gameID={gameID} />
    </View>
  );
};
