import React from "react";
import { useTranslate } from "@unboared/base-ui.all";
import { Player } from "~/model/player";
import { BaseProfileSetting } from './base_profile_setting'

export type ProfileSettingProps = {
    player: Player;
    onSubmit: (player: Player) => void;
    onClose: () => void;
};

export const ProfileSetting = ({ player, onSubmit, onClose }: ProfileSettingProps) => {
    const { translate } = useTranslate();

    return (
        <BaseProfileSetting
            player={player}
            labelTxt={translate('gamepad.profileSetting.change.usernameLabel')}
            submitTxt={translate('gamepad.profileSetting.change.submitText')}
            onSubmitted={onSubmit}
            onClose={onClose}
        />
    );
};
