import React, { useState } from 'react';
import { Platform } from 'react-native';

import { Button, Text, base_color } from '@unboared/base-ui.all';
import { useMargin } from '../hooks/useMargin';
import { useAuth } from '~/services/auth';

export const GuestSignIn = () => {
  const { mediumMargin } = useMargin();
  const loginAsGuest = useAuth((state) => state.loginAsGuest);
  // const loginWithoutAccount = useAuth((state) => state.loginWithoutAccount);
  const loginFunction = Platform.select({
    native: () => loginAsGuest,
    default: () => loginAsGuest,
  })();

  return (
    <>
      <Button
        textPreset="medium"
        tx="common.signin.guest.submitButton"
        style={mediumMargin}
        onPress={loginFunction}
      />
      <Text
        preset="caption"
        tx="common.signin.guest.feature1"
        style={{
          alignSelf: 'center',
          fontWeight: '600',
          color: base_color.light_tertiary,
        }}
      />
      <Text
        preset="caption"
        tx="common.signin.guest.feature2"
        style={{
          alignSelf: 'center',
          fontWeight: '600',
          color: base_color.light_tertiary,
        }}
      />
    </>
  );
};
