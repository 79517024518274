import env from '~/config/env';
import { ENV } from '~/config/env';

/* Communication server config */
export const PROD_WSS = ENV.PROD_SOCKET_SERVER;
export const TEST_WSS = ENV.TEST_SOCKET_SERVER || PROD_WSS;
export const DEV_WSS = ENV.DEV_SOCKET_SERVER || TEST_WSS || PROD_WSS;

export const getSocketServer = () => {
  if (env.isProdMode()) {
    return PROD_WSS;
  } else if (env.isDevMode()) {
    return DEV_WSS;
  } else if (env.isTestMode()) {
    return TEST_WSS;
  } else {
    env.error('Unknown environement mode');
    throw 'Error';
  }
};

// General configs
export const GAME_NAME = 'platform';
export const DEFAULT_SCENE = 'home';
export const DEFAULT_LEVEL_NAME = 'default';

export const RESTRICT_ACCESS = false;
export const SHOW_CONNEXION_INFOS_ON_OPEN = false;

export const ACCESS_VERIFICATION = false;
export const ACCESS_TOKEN = 'WeLoveGames';
export const CAMPAIGN_ID = 'none';
export const TEST_GAME =  undefined /* {
  screen:   'http://0.0.0.0:8000',
  gamepad:  'http://0.0.0.0:8000/gamepad.html',
}  */

// Version des visuels
export const VISUAL_CONNEXION_INFOS_GAMEPAD: boolean = false; //
export const VISUAL_CONNEXION_INFOS_SCREEN: string = 'Video'; // choose between: "Modal", "Blocks" or "Video"

// Assets
export const PUBLIC_ASSETS = 'https://developers.unboared.com/public-assets/';
export const CGU = PUBLIC_ASSETS + 'pdf/CGU-PlayUnboared.pdf';

export const UNBOARED_DEMO_VIDEO_URL =
  PUBLIC_ASSETS + 'video/Unboared_DemoVideo.mp4';

export const PLAY_UNBOARED_DOMAIN = 'play.unboared.com';

export const ASSETS = {
  backgroundGames: require('./assets/images/backgroundGames.png'),
};

// Endpoints
export const GAME_ENDPOINT = '/game/';
export const ROOM_ENDPOINT = '/gamepad/';

export const getGameURL = (host: string, game: string) => {
  return host + GAME_ENDPOINT + game;
};

export const getRoomURL = (host: string, room: number | string) => {
  return host + ROOM_ENDPOINT + room;
};

export function makeid(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const getAdventurerURL = (seed: string) => {
  return `https://api.dicebear.com/5.x/adventurer/png?seed=${seed}&backgroundColor=transparent`;
};

export const sampleAvatar = (): string => {
  const seed = makeid(8);
  return getAdventurerURL(seed);
};

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyCMiw0ST6sRUzcGCjltGE_q36gcxl6XF5k',

  authDomain: 'unboared.firebaseapp.com',

  projectId: 'unboared',

  storageBucket: 'unboared.appspot.com',

  messagingSenderId: '803182974472',

  appId: '1:803182974472:web:8a1f208c65da07792763bd',

  measurementId: 'G-82N796KB49',
};
