/* Declare environment variables */
// import { MODE, DEV_SOCKET_SERVER, TEST_SOCKET_SERVER, PROD_SOCKET_SERVER } from '@env'

let MODE = 'prod';
export const URL_TEST_GAME_SCREEN =
  MODE === 'dev' && '' && 'http://192.168.1.95:19007/';
export const URL_TEST_GAME_GAMEPAD =
  MODE === 'dev' && '' && 'http://192.168.1.95:19007/gamepad';

const DEV_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com'; //"http://192.168.1.95:8000"
const TEST_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com';
const PROD_SOCKET_SERVER = 'https://socket-server-v03.herokuapp.com';


export const isProdMode = (): boolean => MODE === 'prod'; // for production
export const isDevMode = (): boolean => MODE === 'dev'; // for development
export const isTestMode = (): boolean => MODE === 'test'; // for test = development + special environment


export const ENV = {
  MODE,
  DEV_SOCKET_SERVER,
  TEST_SOCKET_SERVER,
  PROD_SOCKET_SERVER,
  isProdMode,
  isDevMode,
  isTestMode,
};

export const printEnv = () => {
  let mode = MODE;
  console.log(`
    NODE_ENV=${mode}
    DEV_SOCKET_SERVER=${DEV_SOCKET_SERVER}
    TEST_SOCKET_SERVER=${TEST_SOCKET_SERVER}
    PROD_SOCKET_SERVER=${PROD_SOCKET_SERVER}
  `);
};

/* Declare loging variables */

export const debug = (value: string): void => {
  if (isDevMode()) {
    console.log("[DEBUG] ", value);
  }
};

export const info = (value: string): void => {
  console.log("[INFO] ", value);
};

export const warning = (value: string): void => {
  console.log("[WARNING] ", value);
};

export const error = (value: string): void => {
  console.error(value);
};


export const log = {
  debug,
  info,
  error,
};
