import React from "react";
import { ViewStyle } from "react-native";

import { Text } from "@unboared/base-ui.all";

import { RankIconContainer } from "./RankIconContainer";

export type RankIconProps = {
    position: number;
    color: string;
    borderColor: string;
    textColor: string;
    size: number;
    avatarSize: number;
}

/**
 * The icon displayed on players' badges on the podium.
 */
export const RankIcon = (props: RankIconProps) => {
    const { position, color, borderColor, textColor, size, avatarSize } = props

    const textStyles = {
        color: textColor,
        fontWeight: "bold",
        fontSize: 0.6 * size,
    } as ViewStyle

    return (
        <RankIconContainer
            avatarSize={avatarSize}
            backgroundColor={color}
            borderColor={borderColor}
            size={size}
        >
            <Text text={`#${position}`} style={textStyles} />
        </RankIconContainer>
    );
};