import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Player } from '@unboared/native.types';
import { Avatar, Username } from '@unboared/design.all';
import { player_color, space, useNormalize } from '@unboared/base-ui.all';

import { RankIcon } from './RankIcon';
import { ScoreIcon } from './ScoreIcon';

/* Propeties of score position components */
export type ScorePositionProps = ScoreProps & {
  /* The position of the player */
  position: number;
  /* The color of the player */
  color?: string;
  /* Additional styles for the component */
  style?: any;
  /* If true, displays shadow behind the badge */
  shadow?: boolean;
  /* The width of the PositionScore badge */
  width?: number;
};

/**
 * Displays the highscore of a player on the podium. 
 */
const PodiumScore = (props: ScorePositionProps) => {
  const {
    position,
    player,
    score,
    size,
    color: colorProp,
    showPosition = true,
    style,
    shadow,
  } = props

  /* Create styles */
  const color = colorProp || player.color
  const { normalize } = useNormalize();
  const styles = podiumStyles(normalize, size, color, shadow)

  return (
    <View style={[{ alignItems: 'center' }, style]}>
      <View style={styles.container}>
        {/* If shadow, displays the score shadow */}
        {shadow && <ScoreIcon color={color} score={score} size={size} shadow />}
        {/* Displays the avatar of the player */}
        <Avatar style={styles.avatar} name={player.avatar} color={color} size={size} />

        {/* Displays the position of the player */}
        {showPosition && (
          <RankIcon
            position={position}
            size={normalize(23)}
            avatarSize={normalize(size)}
            color={color}
            textColor={'#FFF'}
            borderColor={'#FFF'}
          />
        )}
      </View>
      {/* Displays the username of the player */}
      <Username style={styles.username} name={player.username} color={color} size={0.17 * size} />
      {/* Displays the score badge */}
      <View style={styles.scoreIcon}>
        <ScoreIcon color={color} score={score} size={size} />
      </View>
    </View>
  );
};


/* Stylesheet */
const podiumStyles = (normalize: (x: number) => number, size: number, color?: string, shadow?: boolean) =>
  StyleSheet.create({
    container: {
      width: normalize(size),
      height: normalize(size),
    },
    scoreIcon: {
      position: 'absolute',
      width: normalize(size),
      height: normalize(size),
    },
    avatar: {
      borderRadius: '100%',
      boxShadow: shadow && `0px 0px ${0.3 * size}px ${color}`,
    },
    username: {
      top: -20,
      minWidth: normalize(1.2 * size),
      textAlign: 'center',
      paddingHorizontal: normalize(space.medium),
      boxShadow: shadow && `0px 0px ${0.3 * size}px ${color}`,
    }
  })

export type ScoreProps = {
  player: Player;
  score: number;
  maxScore?: number;
  size: number;
  showPosition?: boolean;
  shadow?: boolean;
};

export const FirstPositionScore = (props: ScoreProps) => (
  <PodiumScore position={1} color={player_color[0]} {...props} />
);
export const SecondPositionScore = (props: ScoreProps) => (
  <PodiumScore position={2} color={player_color[3]} {...props} />
);
export const ThirdPositionScore = (props: ScoreProps) => (
  <PodiumScore position={3} color={player_color[2]} {...props} />
);

export default PodiumScore;
