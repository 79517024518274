import { PUBLIC_ASSETS, getAdventurerURL } from "./const";

export const CODE_UNGUYS = new Map([
    ['al', getAdventurerURL('al')],
    ['dzdefrgthyo', getAdventurerURL('dzdefrgthyo')],
    ['vivatechUnboared', getAdventurerURL('vivatechUnboared')],
    ['SugaBGP', getAdventurerURL('SugaBGP')],
    ['david-albert-unboared', PUBLIC_ASSETS + 'images/avatars/dav.png'],
    ['victorien-defosse-unboared', PUBLIC_ASSETS + 'images/avatars/victo.png'],
    ['gabriel-kenig-unboared', PUBLIC_ASSETS + 'images/avatars/team-gab.png'],
    ['thomas-raulin-unboared', PUBLIC_ASSETS + 'images/avatars/team-tom.png'],
    ['vivatech-nico-896', PUBLIC_ASSETS + 'images/avatars/Vivatech2023_Unguy_Nico.png'],
    ['vivatech-artyu-185', PUBLIC_ASSETS + 'images/avatars/Vivatech2023_Unguy_Artyu.png'],
    ['vivatech-richie-752', PUBLIC_ASSETS + 'images/avatars/Vivatech2023_Unguy_Richie.png'],
    ['vivatech-jcampi-684', PUBLIC_ASSETS + 'images/avatars/Vivatech2023_Unguy_Jcampi.png'],
  ]);