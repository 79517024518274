import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';

import { useActiveUserManager, } from '~/services/user';
import { HighscoresScreen } from '~/screens/common/highscores';

/* Import screens for the screen flow  */
import { UnboaredScreenApp } from '~/screen_app';

/* Import screens for the gamepad flow  */
import { UnboaredGamepadApp } from '~/gamepad_app';
import { Route, Router, Routes } from '~/navigation/router';
import { useLocationMemo } from './locationMemo';
import { AccessPage } from '~/screens/gamepad/access';
import { UserInfosScreen } from '~/screens/screen/user_infos';

/**
 * Main flow. This flow contains one application for the screen and
 * one for the gamepad.
 */
const MainRouter = () => {
  // Update the user data according to the authentified user
  useActiveUserManager();

  return (
    <Router>
      <MainRoutes />
    </Router>
  );
};

const MainRoutes = () => {

  // If a target location was stored, navigate to this location
  const navigate = useNavigate();

  const { location, clearLocation } = useLocationMemo();
  useEffect(() => {
    if (location) {
      const path = `${location?.pathname}${location?.search}`;
      clearLocation();
      navigate(path);
    }
  }, []);

  useEffect(() => {
    if (Platform.OS == 'web') {
      document.title = 'Unboared';
    }
  }, [location]);

  return (
    <>
      <UserInfosScreen />
      <Routes>
        <Route path="/gamepad" element={<AccessPage />} />
        <Route path="/gamepad/:roomID" element={<UnboaredGamepadApp />} />
        <Route path="/highscores/:gameID" element={<HighscoresScreen />} />
        <Route path="*" element={<UnboaredScreenApp />} />
      </Routes>
    </>
  );
};


export default MainRouter;
