import React, { useState } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";

import { useNormalize, useTranslate, Text, Heading, Button } from "@unboared/base-ui.all";
import { useScreenInfos } from "@unboared/utils.orientation";

import { PasswordInput, useMargin } from "~/screens/common/signin";
import { strengthChecker } from "~/screens/common/signup";
import { useAuth } from "~/services/auth";
import { ActivityIndicator } from '~/components/common/loaders'

export type AuthPageProps = {
  actionCode: string,
  continueUrl: string,
  lang: string,
}

export const ChangePassword = ({ actionCode, continueUrl, lang }: AuthPageProps) => {
  /* Create styles */
  const { normalize } = useNormalize()
  const styles = makeStyles(normalize)
  const { smallMargin } = useMargin()

  /* Use translator */
  const { translate } = useTranslate()

  const { isPortrait } = useScreenInfos()

  const [password, setPassword] = useState("");
  const { passwordErrorMessage, onResetPassword } = useAuth()

  const strengthPassword = strengthChecker(password)
  const loading = useAuth(state => state.loading)


  return (
    <View style={styles.flexCenter}>
      {
        loading ?
          <ActivityIndicator /> :
          (
            <View style={styles.content}>
              <Heading type="h2" style={[smallMargin, styles.textCenter]} tx="common.forgotPassword.title" />
              <Text style={[smallMargin, styles.textCenter]} tx="common.forgotPassword.description" />
              <PasswordInput
                password={password}
                onChangeValue={setPassword}
                label={translate("common.forgotPassword.label")}
                placeholder={translate("common.forgotPassword.placeholder")}
                helperText={passwordErrorMessage || (password.length > 0 ? `Security Level : ${strengthPassword.type}` : undefined)}
                passwordErrorMessage={translate(passwordErrorMessage)}
              />
              <Button style={smallMargin} tx="common.forgotPassword.submitButton" onPress={() => onResetPassword(password, actionCode, continueUrl, lang)} />
            </View>
          )
      }
    </View>
  );
};

/* Stylesheet */
const makeStyles = (normalize: (x: number) => number) => StyleSheet.create(
  {
    container: {
      flexDirection: "row",
    },
    textCenter: {
      textAlign: 'center',
    },
    flexCenter: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    content: {
      width: normalize(250),
      justifyContent: "center"
    }
  })