import { useEffect } from 'react';

import { createGlobalStorage } from '@unboared/utils.storage';
import { UserAPI } from '~/services/user';
import { initialUserState, UserState } from './user_state';
import { Player } from '@unboared/native.types';
import { useAuth } from '~/services/auth';
import { UserBuilder, UserData } from '~/model/user';
import { DevicesInfosApi } from '../device_infos';

/* The store that keep informations about the authentification */
export const useActiveUser = createGlobalStorage<UserState>((set: any, get: any) => ({
  ...initialUserState,

  /**
   * Create a new user in the data base.
   */
  createUser: (auth: any, player: Player) => {
    set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })); // start loading

    const userToCreate: UserData = {
      ...UserBuilder.makeFromAuth(auth),
      avatar_url: player.avatar,
      username: player.username,
      email: player.email
    };

    UserAPI
      .createUser(userToCreate)
      .then((createdUser) => {
        set((state: UserState) => ({
          ...state,
          loading: false,
          user: createdUser,
        }));
      })
      .catch((error) => {
        set((state: UserState) => ({
          ...state,
          loading: false,
          errorMessage: error,
        }));
      });
  },

  /**
   * Load user data from the database.
   * @param user the user identifier
   * @returns user's data
   */
  loadUserData: (uid: string) => {
    set((state: UserState) => ({ ...state, loading: true, errorMessage: '' })); // start loading
    if (uid) {
      UserAPI
        .getUser(uid)
        .then((user) => {
          DevicesInfosApi.updateDevicesInfos(user.uid).then(() => {
            set((state: UserState) => ({ ...state, loading: false, user: user })); // user found in database
          }).catch((err) => {
            set((state: UserState) => ({ ...state, loading: false, user: user })); // user found in database
            console.error(`[ERR0R] Cannot update devices infos - ${err}`)
          })
        })
        .catch((error) => {
          set((state: UserState) => ({
            ...state,
            loading: false,
            errorMessage: error,
          })); // user not found in database
        });
    } else {
      set((state: UserState) => ({
        ...state,
        loading: false,
        errorMessage: '',
      }));
    }
  },

  /**
   * Set the authentified user.
   */
  setUser: (user: any) => set({ user: user }),

  /**
   * Set the user for guest.
   */
  setRandomUser: () =>
    set((state: UserState) => ({
      ...state,
      loading: false,
      user: UserBuilder.makeRandom(),
    })),
}));

/**
 * Hook that can be used to get the authentified user.
 *
 * @returns the current user
 */
export const useActiveUserManager = () => {
  const { auth } = useAuth();
  const loadUserData = useActiveUser((state) => state.loadUserData);
  useEffect(() => {
    if (auth && auth.uid) {
      loadUserData(auth.uid);
    }
  }, [auth]);
};