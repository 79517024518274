import React from 'react';
import { StyleSheet, View } from 'react-native';

import { space, useNormalize } from '@unboared/base-ui.all';

import {
  FirstPositionScore,
  SecondPositionScore,
  ThirdPositionScore,
} from './score/podium/PodiumScore';

/**
 * Displays the top 3 best players.
 */
export const Podium = ({ ranking }: any) => {
  /* Create styles */
  const { normalize } = useNormalize();
  const styles = podiumStyles(normalize)

  return (
    <View style={styles.container}>
      {/* If at least 2 highscores, show the second */}
      {(ranking.length > 1 && (
        <SecondPositionScore
          style={styles.aThird}
          player={ranking[1]}
          score={ranking[1].score}
          size={95}
        />
      )) || <View style={styles.aThird}></View>}

      {/* If at least 1 highscore, show the first */}
      {ranking.length > 0 && (
        <FirstPositionScore
          style={styles.aThird}
          player={ranking[0]}
          score={ranking[0].score}
          size={120}
          shadow
        />
      )}

      {/* If at least 3 highscores, show the third */}
      {(ranking.length > 2 && (
        <ThirdPositionScore
          style={styles.aThird}
          player={ranking[2]}
          score={ranking[2].score}
          size={95}
        />
      )) || <View style={styles.aThird}></View>}
    </View>
  );
};


/* Stylesheet */
const podiumStyles = (normalize: (x: number) => number) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-end',
      width: normalize(600),
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    aThird: {
      width: "33%"
    }
  });


export default Screen;
