import React, { useEffect } from 'react';
import { Platform, Pressable, View, ViewStyle } from 'react-native';
import { useLinkTo } from '~/navigation/router';

import {
  useNormalize,
  useTranslate,
  accent_color,
  Text,
} from '@unboared/base-ui.all';
import { useScreenInfos } from '@unboared/utils.orientation';

import { log } from '~/config/env';
import { useAuth } from '~/services/auth';
import { useAnalyticsAccessEvent } from '~/services/firebase';

import { useMargin } from './hooks/useMargin';
import { EmailPasswordSignIn } from './components/email_password';
import { GoogleSignIn } from './components/google';
import { GuestSignIn } from './components/guest';
import { ActivityIndicator } from '~/components/common/loaders';
import { CentralSeparator, Separator } from '../separator';

/**
 * Sign in page.
 */
export const SignIn = () => {
  log.debug('Mount SignIn');
  useAnalyticsAccessEvent('platform/signin')
  
  const { normalize } = useNormalize();
  const { isPortrait } = useScreenInfos();
  const loading = useAuth((state) => state.loading);
  const globalAuth = useAuth();
  
  const styles = {
    container: {
      flexDirection: 'row',
    } as ViewStyle,
    flexCenter: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    } as ViewStyle,
    content: {
      width: normalize(250),
      justifyContent: 'center',
    } as ViewStyle,
  };

  return (
    <View style={[styles.flexCenter, styles.container]}>
      <View style={styles.flexCenter}>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <View style={styles.content}>
            <EmailPasswordSignIn />
            <Separator />
            <GoogleSignIn />
            {isPortrait && (
              <>
                <Separator />
                <GuestSignIn />
              </>
            )}
            <CreateAccountLink />
          </View>
        )}
      </View>
      {isPortrait || (
        <>
          {loading || <CentralSeparator />}
          <View style={styles.flexCenter}>
            {loading || (
              <View style={styles.content}>
                <GuestSignIn />
              </View>
            )}
          </View>
        </>
      )}
    </View>
  );
};

/**
 * The link to the signup page.
 */
const CreateAccountLink = () => {
  const { translate } = useTranslate();
  const { largeMargin } = useMargin();

  const linkTo = useLinkTo();
  const resetFields = useAuth((state) => state.resetFields);

  return (
    <Pressable
      onPress={() => {
        resetFields();
        linkTo('/signup');
      }}
    >
      <Text
        style={{
          ...largeMargin,
          fontWeight:'bold',
          alignSelf: 'center',
        }}
      >
        {translate('common.signin.noAccount.message')}{' '}
        <Text
          style={{
            color: accent_color.important,
          }}
          tx="common.signin.noAccount.createAccount"
        />
      </Text>
    </Pressable>
  );
};

export default SignIn;
