import React, { useRef, useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Image,
  Platform,
  ImageBackground,
} from 'react-native';
import { motion } from 'framer-motion/dist/framer-motion';

import {
  Button,
  Heading,
  Icon,
  Text,
  size,
  space,
  useNormalize,
  useTranslate,
} from '@unboared/base-ui.all';
import { Theme, defaultTheme, useTheme } from '@unboared/base-ui.all';

import { log } from '~/config/env';

import { HeroImage } from './hero_image';
import { ShimmerPlaceholder } from '~/components/common/loading_placeholder';
import { HeroGameFeatures } from './hero_game_features';

export const HeroSlide = ({ gameItems, activeGame, loading }) => {
  log.debug('In Home [HeroSlide]');

  if (loading) {
    return (
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <HeroInfos
          item={null}
          style={{ width: '40%', height: '100%' }}
          loading={loading}
        />
      </View>
    );
  }

  return (
    <>
      {gameItems.map((game, index) => (
        <HeroSlideItem
          key={index}
          item={game}
          active={activeGame === index}
          loading={loading}
        />
      ))}
    </>
  );
};

export const HeroInfos = ({ item, style, loading }) => {
  const theme = useTheme();
  const currentTheme = (theme as Theme) || defaultTheme;
  const { normalize } = useNormalize();

  return (
    <View style={[{ justifyContent: 'center' }, style]}>
      <View style={{ marginBottom: normalize(space.tiny) }}>
        <ShimmerPlaceholder
          height={normalize(theme.sizeH1)}
          width="50%"
          visible={!loading}
        >
          <Heading type="h1">{loading || item.title}</Heading>
        </ShimmerPlaceholder>
      </View>
      <HeroGameFeatures item={item} loading={loading} />
      <View style={{ marginBottom: normalize(space.none) }}>
        <ShimmerPlaceholder
          style={{ marginBottom: normalize(space.smaller) }}
          height={normalize(theme.sizeP)}
          width="100%"
          visible={!loading}
        >
          <Text
            numberOfLines={3}
            preset='medium'
            ellipsizeMode="tail"
            style={{ textShadow: "2px 2px 4px rgba(0,0,0,.45)", textAlign: 'justify' }}
          >
            {loading || item.summary}
          </Text>
        </ShimmerPlaceholder>
        <ShimmerPlaceholder
          style={{ marginBottom: normalize(space.smaller) }}
          height={normalize(theme.sizeP)}
          width="100%"
          visible={!loading}
        />
        <ShimmerPlaceholder
          height={normalize(theme.sizeP)}
          width="90%"
          visible={!loading}
        />
      </View>

      <View style={{ alignSelf: 'flex-start' }}>
        <ShimmerPlaceholder
          height={normalize(theme.sizeH3)}
          shimmerStyle={{ width: normalize(50) }}
          visible={!loading}
        >
          <Button
            icon="play"
            textPreset="medium"
            tx="screen.homePage.hero.play"
          />
        </ShimmerPlaceholder>
      </View>
    </View>
  );
};

export const HeroSlideItem = (props: any) => {
  const item = props.item;
  const loading = props.loading;

  const { normalize } = useNormalize();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: props.active ? 1 : 0 }}
      transition={{ duration: 0.4 }}
    >
      <View
        style={{
          position: 'absolute',
          paddingBottom: normalize(space.smaller),
          paddingRight: normalize(space.larger),
          width: '100%',
          height: '100%',
        }}
      >
        <View
          style={{
            flex: 1,
            width: '100%',
            height: '100%',
            alignSelf: 'stretch',
          }}
        >
         <Image
            source={{ uri: item.cover ? item.cover : item.icon }}
            style={{
              position:'absolute',
              zIndex:0,
              width: '100.4%',
              height: '102%',
              left:'-0.2%',
              top:'-1%',
              borderRadius: normalize(size.borderRadius.medium),
              flexDirection: 'row',
              filter: `blur(${normalize(8)}px)`,
            }}
          />
          

          <HeroImage
            src={item.cover ? item.cover : item.icon}
            width="100%"
            height="100%"
            borderRadius={normalize(size.borderRadius.medium)}
            loading=""
          />
          
          <HeroInfos
            item={item}
            style={{
              position: 'absolute',
              marginLeft: normalize(space.large),
              width: '40%',
              height: '100%',
              zIndex:999,
            }}
            loading={loading}
          />
        </View>
      </View>
    </motion.div>
  );
};
