import React, { useEffect } from 'react';
import { Pressable, TextStyle, View } from 'react-native';

import { useScene } from '@unboared/lib';
import { Avatar } from '@unboared/design.all';
import {
  accent_color,
  Heading,
  space,
  Text,
  useNormalize,
} from '@unboared/base-ui.all';
import { useTheme } from '@unboared/utils.theme';

import { log } from '~/config/env';
import { useAuth } from '~/services/auth';
import { useActiveUser } from '~/services/user';
import { useGlobalPopup } from '~/components/screen/popup/global_popup';

import { LogoutConfirmation } from './components/account/logout';
import { DeleteAccountConfirmation } from './components/account/deleteAccount';
import { ChangePasswordConfirmation } from './components/account/changePassword';
import { useAnalyticsAccessEvent } from '~/services/firebase';

const SettingSection = ({ title, tx, children }: any) => {
  const theme = useTheme((state) => state.theme);
  const { normalize } = useNormalize();

  return (
    <View
      style={{
        flexDirection: 'row',
        padding: normalize(space.small),
        borderTopWidth: normalize(1),
        borderBottomWidth: normalize(1),
        borderColor: theme.textColor,
      }}
    >
      <View style={{ flex: 1 }}>
        <Heading text={title} tx={tx} />
      </View>
      <View style={{ flex: 2 }}>{children}</View>
    </View>
  );
};

const SettingSubSection = ({ infos, children }: any) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <View style={{}}>{infos}</View>
      <View style={{}}>{children}</View>
    </View>
  );
};

const TextAction = ({ text, tx, onPress }: any) => {
  const { normalize } = useNormalize();

  const textActionStyle = {
    fontWeight: 'bold',
    textAlign: 'right',
    color: accent_color.important,
    margin: normalize(space.tiny),
  } as TextStyle;

  return (
    <Pressable onPress={onPress}>
      <Text style={textActionStyle} text={text} tx={tx} />
    </Pressable>
  );
};

const SettingsContent = () => {
  const theme = useTheme((state) => state.theme);
  const { normalize } = useNormalize();

  const auth = useAuth((state) => state.auth);
  const resetFields = useAuth((state) => state.resetFields);
  const user = useActiveUser((state) => state.user);

  const open = useGlobalPopup((state) => state.open);

  const onCustomClose = () => {
    resetFields();
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
        padding: normalize(space.larger),
        paddingRight: normalize(100),
      }}
    >
      {auth && auth.isLogin ? (
        <SettingSection tx="screen.settingPage.myAccount.title">
          <SettingSubSection
            infos={
              <>
                <Text
                  style={{
                    margin: normalize(space.tiny),
                    fontWeight: 'bold',
                  }}
                  text={auth.email}
                />
                <Avatar
                  style={{ margin: normalize(space.tiny) }}
                  name={user?.avatar_url}
                  color={accent_color.important}
                />
              </>
            }
          >
            {auth.isPassword && (
              <TextAction
                tx="screen.settingPage.myAccount.action.changePassword.title"
                onPress={() =>
                  open(<ChangePasswordConfirmation />, onCustomClose)
                }
              />
            )}
            <TextAction
              tx="screen.settingPage.myAccount.action.changeUnguy.title"
              onPress={() => open(<></>)}
            />
            <TextAction
              tx="screen.settingPage.myAccount.action.logout.title"
              onPress={() => open(<LogoutConfirmation />)}
            />
            <TextAction
              tx="screen.settingPage.myAccount.action.deleteAccount.title"
              onPress={() => open(<DeleteAccountConfirmation />, onCustomClose)}
            />
          </SettingSubSection>
        </SettingSection>
      ) : (
        <Text text="Connect to your account to access settings" />
      )}
    </View>
  );
};
export const ScreenSettingsPage = () => {
  log.debug('In Settings');
  useAnalyticsAccessEvent("platform/settings")

  const { loadScene } = useScene();
  useEffect(() => {
    loadScene('settings');
  }, []);

  return <SettingsContent />;
};
