import { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import { CAMPAIGN_ID } from '~/config/const';
import { LoginType, User, UserData } from '~/model/user';
import { db as firebaseDB } from '~/services/firebase';

import { DeviceInfos, getDeviceInfos } from '~/utils/device_infos';

export const UserAPI = {
  /**
   * Get the user from its id.
   *
   * @param uid the user identifier
   * @returns 
   */
  getUser: async (uid: string): Promise<UserData> => {
    const docRef = doc(firebaseDB, 'users', uid);
    let userDoc = await getDoc(docRef)
    let userData = userDoc.data()
    return userData as UserData;
  },

  /**
   * Get informations about devices that were connected to the account.
   * @param uid the user id
   * @param total the limit of devices infos
   * @returns device informations
   */
  getDevicesInfos: async (uid: string, total: number): Promise<Array<DeviceInfos>> => {
    let devicesInfos: any = [];

    /* Set document limitation */
    let limitValue = total <= 0 ? 10000 : total;

    /* Prepare query */
    let devicesRef = collection(firebaseDB, 'users', uid, 'devices');
    const q = query(devicesRef, orderBy('last_connection', 'desc'), limit(limitValue));

    /* Call query */
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      devicesInfos.push(doc.data());
    });

    return devicesInfos;
  },


  /**
   * Create a new user in the database
   * @param user informations about the user
   * @returns a promise function
   */
  createUser: async (user: UserData): Promise<UserData> => {
    const serverTime = serverTimestamp()

    // Get user doc ref
    const docRef = doc(firebaseDB, 'users', user.uid);

    // Get device informations
    let deviceInfos: any = await getDeviceInfos();
    deviceInfos.last_connection = serverTime

    /* Get device document ref */
    let deviceRef = doc(firebaseDB, 'users', user.uid, 'devices', deviceInfos.name);

    /* Add iso code to the user */
    user.configs = {
      iso_code: deviceInfos.geolocation.iso_code,
      language: navigator.language || navigator.userLanguage,
    }
    /* Add the created informations */
    user.created = {
      timestamp: serverTime,
      login_type: user.login_type,
      campaign: CAMPAIGN_ID,
      device: deviceRef
    }

    /* Add user  */
    await setDoc(docRef, user)

    /* Add device infos */
    await setDoc(deviceRef, deviceInfos)

    return user
  },


  /**
   * Update devices informations
   * @param uid the user id
   */
  updateDevicesInfos: async (uid: string) => {
    let deviceInfos: any = await getDeviceInfos();
    deviceInfos.last_connection = serverTimestamp()

    /* Get device document ref */
    let deviceRef = doc(firebaseDB, 'users', uid, 'devices', deviceInfos.name);

    /* Call query */
    await setDoc(deviceRef, deviceInfos)
  },



  /**
   * Update profile of an user.
   * @param uid the user identifier
   * @param avatar the new avatar url
   * @param username the new username
   */
  updateProfile: async (uid: string, avatar: string, username: string): Promise<void> => {
    const docRef = doc(firebaseDB, 'users', uid);
    updateDoc(docRef, {
      avatar_url: avatar,
      username: username,
    })
      .then(() => {
        console.log('[SUCCESS] Success to update profile settings');
      })
      .catch(() => {
        console.error('[FAIL] Failed to update profile settings');
      });
  }
};

export const useUser = (id: string): User => {
  const [user, setUser] = useState<any>();
  useEffect(() => {
    const loadUser = async (id: string) => {
      if (id) {
        UserAPI.getUser(id).then(setUser).catch(console.log);
      }
    }
    loadUser(id);
  }, [id]);

  return user;
};
