import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { ActivityIndicator } from "~/components/common/loaders";
import { UserAuthentication } from "~/components/common/view/profile_setting";
import { log } from "~/config/env";
import { INITAL_PLAYER } from "~/model/player";
import { AuthContainer } from "~/navigation/AuthContainer";
import { useAuth } from "~/services/auth";
import { useActiveUser } from "~/services/user";

export const UserInfosScreen = () => {
    log.debug('In App [Load user]');

    const { auth, initialLoading } = useAuth();
    const { user, createUser, loading } = useActiveUser();

    log.info(auth)
    log.info(`${user}`)

    const launchUI = (newPlayer: any) => {
        createUser(auth, newPlayer);
    };

    // Deprecated (for WithoutAccount subscription)
    // useEffect(() => {
    //     if (auth && !user && !auth.isLogin) {
    //         setRandomUser();
    //     }
    // }, [auth, user]);

    return (
        <>
            {auth && !user && auth.isLogin && (
                <View style={styles.over}>
                    <AuthContainer>
                        {initialLoading || loading ? (
                            <ActivityIndicator />
                        ) : (
                            <UserAuthentication player={INITAL_PLAYER} onSubmit={launchUI} />
                        )}
                    </AuthContainer>
                </View>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    over: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 100,
        elevation: 100,
        backgroundColor: '#070219',
    }
})