import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Text, space, useNormalize } from '@unboared/base-ui.all';

import { DEFAULT_LEVEL_NAME } from '~/config/const';
import { useParams } from '~/navigation/router';
import { Branding } from '~/components/common/branding';
import { useGame } from '~/services/games_api/games_api';
import { useHighscores } from '~/services/scores_api';

import { Podium } from './podium';
import { ScrollingHighscores } from './scrolling_highscores';

/** 
 * This screen displays highscores for a specific game.
 */
export const HighscoresScreen = () => {
  /* Create styles */
  const { normalize } = useNormalize();
  const styles = highscoreStyles(normalize)

  /* Get highscores from the database */
  const { gameID, levelID } = useParams();
  const game = useGame(gameID || 'webview') as any;
  const highscores = useHighscores(gameID, -1, levelID || DEFAULT_LEVEL_NAME);

  if (!highscores) {
    return <></>;
  }

  return (
    <View style={styles.container}>
      {/* Branding */}
      <Branding />
      {/* Informations about the game */}
      <View style={styles.infosContainer}>
        <Image
          source={{ uri: game?.logo || game?.icon }}
          style={styles.img}
        />
      </View>
      {/* Three best players */}
      <View style={styles.podiumContainer}>
        {highscores.length === 0 && (
          <Text preset="large" text="No highscores for this game" />
        )}
        <Podium ranking={highscores} />
      </View>
      {/* Highscores of other player */}
      <View style={styles.otherContainer}>
        <ScrollingHighscores highscores={highscores} />
      </View>
    </View>
  );
};

/* Stylesheet */
const highscoreStyles = (normalize: (x: number) => number) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    img: {
      height: '100%',
      resizeMode: 'contain'
    },
    infosContainer: {
      flex: 0.8,
      paddingVertical: normalize(space.small)
    },
    podiumContainer: {
      flex: 1,
      alignItems: 'center'
    },
    otherContainer: {
      flex: 0.8,
      paddingTop: normalize(space.medium),
      alignItems: 'center',
    }
  });
