import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, Platform, ScrollView, View } from 'react-native';
import { useTheme } from 'rn-css';

import {
  defaultTheme,
  Theme,
  space,
  useNormalize,
  TextInput,
  transparency,
} from '@unboared/base-ui.all';
import { useListener, useScene } from '@unboared/lib';
import { useSound } from '@unboared/utils.sound';

import { useValidate } from './useValidate';
import { HeroSlide } from './components/hero_slide';
import { Section } from './components/collection/section';
import { GameList } from './components/collection/game_list';
import { useGames } from '~/services/games_api';
import { useLinkTo } from '~/navigation/router';
import { isTestMode, log } from '~/config/env';
import { useAnalyticsAccessEvent } from '~/services/firebase';


const useSections = () => {
  const unboaredGames = useGames(isTestMode() ? ['test'] : ['unboared', 'vivatech']);
  const retroGames = useGames(['atari7800']);
  const loading = unboaredGames.length === 0 || retroGames.length === 0;

  return {
    loading,
    sections: [
      {
        txTitle: 'screen.homePage.sections.unboaredGames.title',
        gameItems: unboaredGames,
        index: 0,
      },
      {
        txTitle: 'screen.homePage.sections.retro.title',
        gameItems: retroGames,
        index: 1,
      },
    ],
  };
};

const GameSection = ({
  title,
  txTitle,
  gameItems,
  activeGame,
  loading,
  activeCategory,
  validate,
}: any) => {
  log.debug('In Home [GameSection]');
  const { normalize } = useNormalize();

  return (
    <Section
      style={{ marginTop: normalize(space.smaller) }}
      text={title}
      tx={txTitle}
      size={16}
      loading={loading}
    >
      <GameList
        games={gameItems}
        activeGame={loading || (activeCategory && activeGame.id)}
        size={90}
        loading={loading}
        onPress={validate}
      />
    </Section>
  );
};

function zeroArray(n: number) {
  const arr1 = new Array(n).fill(0);
  return arr1;
}

const HomeContent = ({ sectionItems, loading }: any) => {
  // const unboared = useUnboared()
  const flatListRef = useRef<any>();
  const theme = useTheme();
  const linkTo = useLinkTo();
  const { normalize } = useNormalize();
  const currentTheme = (theme as Theme) || defaultTheme;

  const actionOnPress = (game: any) => {
    linkTo(`/game/${game.id}`);
  };

  const [selectedGame, setSelectedGame] = useState(
    zeroArray(sectionItems.length),
  );
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [playMoveSound] = useSound(currentTheme.song.move);

  const gameItems = sectionItems[selectedCategory].gameItems;
  const gameIndexInSection = selectedGame[selectedCategory];
  const game = gameItems[gameIndexInSection];

  const { validate } = useValidate(game, actionOnPress);

  useListener(
    '__left__',
    () => {
      setSelectedGame((prev) => {
        playMoveSound();
        let newList = [...prev];
        if (prev[selectedCategory] > 0) {
          newList[selectedCategory] = prev[selectedCategory] - 1;
        }
        return newList;
      });
    },
    [selectedCategory, gameItems, playMoveSound],
  );

  useListener(
    '__down__',
    () => {
      setSelectedCategory((prev) => {
        playMoveSound();
        if (prev + 1 < 2) {
          return prev + 1;
        }
        return prev;
      });
    },
    [playMoveSound],
  );

  useListener(
    '__up__',
    () => {
      setSelectedCategory((prev) => {
        playMoveSound();
        if (prev > 0) {
          return prev - 1;
        }
        return prev;
      });
    },
    [playMoveSound],
  );

  useListener(
    '__right__',
    () => {
      setSelectedGame((prev) => {
        playMoveSound();
        let newList = [...prev];
        if (prev[selectedCategory] + 1 < gameItems.length) {
          newList[selectedCategory] = prev[selectedCategory] + 1;
        }
        return newList;
      });
    },
    [selectedCategory, gameItems, playMoveSound],
  );

  useEffect(() => {
    if (!loading) {
      let index = selectedCategory ? 1 : 0;
      flatListRef.current.scrollToIndex({ animated: true, index });
    }
  }, [selectedCategory]);

  return (
    <View style={{ flex: 1, paddingLeft: normalize(space.medium) }}>
      <View style={{ marginVertical:normalize(space.medium), justifyContent:'center' }}>
        <TextInput style={{borderWidth:0, minHeight:normalize(25), backgroundColor:transparency('dark', 0.1),width:normalize(150)}} value="" placeholder='Quick Search'/>
      </View>
      <View style={{ flex: 1 }}>
        <HeroSlide
          gameItems={gameItems}
          activeGame={gameIndexInSection}
          loading={loading}
        />
      </View>
      <FlatList
        ref={flatListRef}
        style={{ flex: 0.8, flexDirection: 'column' }}
        data={sectionItems}
        renderItem={({ item }) => (
          <GameSection
            txTitle={item.txTitle}
            gameItems={item.gameItems}
            activeGame={game}
            loading={loading}
            validate={validate}
            activeCategory={selectedCategory === item.index}
          />
        )}
        keyExtractor={(item) => item.index}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      />
      {/* 
      <ScrollView style={{ flex: 1 }}>
        <GameSection
          title="Games by Unboared"
          gameItems={gameItems}
          activeGame={selectedGame}
          callbackSetSelected={setSelectedGame}
          loading={loading}
          activeCategory={selectedCategory === 0}
        />
        <GameSection
          title="Atari 7800"
          gameItems={gameItems.slice(2)}
          activeGame={selectedGame}
          callbackSetSelected={setSelectedGame}
          loading={loading}
          activeCategory={selectedCategory === 1}
        />
      </ScrollView> */}
    </View>
  );
};
export const ScreenHomePage = () => {
  log.debug('In Home');
  useAnalyticsAccessEvent('platform/home');
  const { loading, sections } = useSections();

  const { loadScene } = useScene();
  useEffect(() => {
    loadScene('home');
  }, []);

  return <HomeContent sectionItems={sections} loading={loading} />;
};
