import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { useNormalize, space } from '@unboared/base-ui.all';
import { Logo as UnboaredLogo } from '@unboared/design.all'
import { useLinkTo } from '~/navigation/router';

/* Displays the logo */
export const Branding = ({ preset = 'beta' }: any) => {

  // Setup styles
  const { normalize } = useNormalize()
  const styles = makeStyles(normalize(space.small))

  // Setup redirection
  const linkTo = useLinkTo()
  const goToHome = () => {
    linkTo('/')
  }

  return (
    <Pressable style={styles.topLeft} onPress={goToHome}>
      <UnboaredLogo preset={preset} size={30} />
    </Pressable>
  )
}

/* Build component styles */
const makeStyles = (padding: number) => StyleSheet.create({
  topLeft: {
    position: 'absolute',
    zIndex: 100,
    elevation: 100,
    top: padding,
    left: padding,
  }
})